import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { PDFExport } from "@progress/kendo-react-pdf";
import Template1 from "./template/Template1";
import Template2 from "./template/Template2";
import Template3 from "./template/Template3";
import Template4 from "./template/Template4";
import Template6 from "./template/Template6";
import { API_URL } from "../apiConfig";
import aiResumeStore from "../store/aiResumes.zustand";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css"; // Core Swiper styles
import "swiper/css/navigation"; // Navigation styles
import "swiper/css/pagination"; // Pagination styles

const ResumeComponent = () => {
  const pdfRefs = useRef([]);
  const [page, setPage] = useState(1);
  const [totalResumes, setTotalResumes] = useState(0); // Total number of resumes
  const zustandAIResumes = aiResumeStore((state) => state.resumes);
  const navigate = useNavigate();

  const fetchAIResumes = async () => {
    try {
      const resumesResponse = await axios.get(
        `${API_URL}/api/v1/generate-summary/active-resumes`,
        {
          params: { page },
          withCredentials: true,
        }
      );
      if (resumesResponse?.data?.success) {
        aiResumeStore.getState().setResumes(resumesResponse?.data?.data);
        setTotalResumes(resumesResponse?.data?.total); // Assuming API returns total count
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAIResumes();
  }, [page]);

  const renderTemplate = (formData, selectedTemplate) => {
    switch (selectedTemplate) {
      case "template1":
        return <Template1 formData={formData} />;
      case "template2":
        return <Template2 formData={formData} />;
      case "template3":
        return <Template3 formData={formData} />;
      case "template4":
        return <Template4 formData={formData} />;
      case "template6":
        return <Template6 formData={formData} />;
      default:
        return <Template1 formData={formData} />;
    }
  };

  return (
    <div className="p-3">
      <div className="flex items-center justify-between">
        {/* Swiper.js Component */}
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            // For responsive layout
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
        >
          {(zustandAIResumes ?? [])
            .filter(
              (el) =>
                Array.isArray(el?.tags) &&
                el?.tags.some((tag) => tag.toLowerCase() === "home")
            )
            .map((item, index) => {
              const formData = {
                resumeId: item?.resumeId || "",
                resumeTitle: item?.resumeTitle || "Untitled",
                eduFlag: item?.eduFlag ?? true,
                workFlag: item?.workFlag ?? true,
                achFlag: item?.achFlag ?? true,
                addFlag: item?.addFlag ?? true,
                skillFlag: item?.skillsFlag ?? true,
                proFlag: item?.proFlag ?? true,
                Details: item?.personalDetails
                  ? [
                    {
                      fullName: item?.personalDetails?.fullName || "",
                      email: item?.personalDetails?.email || "",
                      phone: item?.personalDetails?.phone || "",
                      address: item?.personalDetails?.address || "",
                      website: item?.personalDetails?.website || "",
                      linkedin: item?.personalDetails?.linkedin || "",
                      github: item?.personalDetails?.github || "",
                      about: item?.personalDetails?.summary || "",
                    },
                  ]
                  : [],
                education: item?.education || [],
                experience:
                  item?.experience?.map((exp) => ({
                    jobRole: exp?.jobRole || "",
                    companyName: exp?.companyName || "",
                    companyLocation: exp?.companyLocation || "",
                    fromDate: exp?.fromDate || "",
                    toDate: exp?.toDate || "",
                    jobDescription: exp?.responsibilities,
                  })) || [],
                skills:
                  item?.skills?.map((skill) => ({
                    title: skill?.title || "",
                    skillList: skill?.skillList?.join(", ") || "",
                  })) || [],
                achivawards:
                  item?.achivawards?.map((achi) => ({
                    achivawards: achi || "",
                  })) || [],
                additionalInfo:
                  item?.additionalInfo?.map((info) => ({
                    addInfo: info?.addInfo || "",
                  })) || [],
                projects:
                  item?.projects?.map((proj) => ({
                    title: proj?.title || "",
                    stack: proj?.stack?.join(", ") || "",
                    desc: proj?.desc ? proj?.desc.toString() : "",
                  })) || [],
                profileImage: "",
                templateKey: item?.templateKey,
              };

              return (
                <SwiperSlide key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                  <div
                    key={index}
                    className="snap-start flex-none mx-2 rounded-sm w-80 sm:w-80 sm:h-96 lg:w-[455px] lg:h-[564px] h-[450px] group relative "
                    style={{
                      boxShadow: " 0px 2px 8px 0px #ccc",
                      margin: "10px",
                      border: "1px solid #ccc",
                    }}
                  >
                    <div
                      className="relative h-full mx-2 overflow-hidden text-center bg-white hover:cursor-pointer rounded-2xl"
                      onClick={() => navigate("/dashboard")}
                    >
                      <PDFExport
                        ref={(el) => (pdfRefs.current[index] = el)}
                        paperSize="A4"
                        className="h-1.0"
                        fileName={`${item.resumeTitle || "Untitled"}`}
                      >
                        <div className="scale-[0.3] text-left origin-top-left w-[1000px]">
                          <div className="flex items-center justify-center origin-top-left scale-[1.0] sm:scale-[0.6] md:scale-[1] lg:scale-[1.5] min-h-screen">
                            {item.templateKey === "template1" && <Template1 formData={formData} />}
                            {item.templateKey === "template2" && <Template2 formData={formData} />}
                            {item.templateKey === "template3" && <Template3 formData={formData} />}
                            {item.templateKey === "template4" && <Template4 formData={formData} />}
                            {item.templateKey === "template6" && <Template6 formData={formData} />}
                          </div>
                        </div>
                      </PDFExport>
                    </div>

                    {/* Hoverable div */}
                    <div
                      className="absolute bottom-6 left-1/2 transform -translate-x-1/2 bg-green-700 text-white text-center py-2 px-4 w-1/2 opacity-0 translate-y-4 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-300 ease-in-out rounded-md cursor-pointer"
                      onClick={() => navigate('/login')}
                    >
                      Use This Template
                    </div>
                  </div>

                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div >
  );
};

export default ResumeComponent;
