import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import RoopTable from "../../common/customTableR/table";
import useGlobalStore from "../../../store/global";
import useGlobalLoaderStore from "../../../store/loader";
import { extractErrorMessage, padTwoDigits } from "../../../utils/utils";
import { API_URL } from "../../../apiConfig";
import { PDFExport } from "@progress/kendo-react-pdf";
import Template1 from "../../template/Template1";
import Template2 from "../../template/Template2";
import Template3 from "../../template/Template3";
import Template4 from "../../template/Template4";
import CustomSwitch from "../../common/customSwitch/customSwitch";
import ReusableModal from "../../common/modal/modal";
import CButton from "../../common/button/button";
import { ButtonType } from "../../common/button/buttonType";
import { FaRegEdit, FaRegEye } from "react-icons/fa";

const AIResume = () => {
  const { setToastData } = useGlobalStore();
  const { isLoading, setLoading } = useGlobalLoaderStore();
  const [resumes, setResumes] = useState([]);
  const [formData, setFormData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectResumeId, setSelectResumeId] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [counter, setCounter] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [scaleRatio, setScaleRatio] = useState(1);
  const [preview, setPreview] = useState(false);
  const [showEditResume, setShowEditResume] = useState(false);

  const [updateResume, setUpdateResume] = useState({
    resumeId: "",
    resumeTitle: "",
    eduFlag: true,
    workFlag: true,
    achFlag: true,
    addFlag: true,
    skillFlag: true,
    proFlag: true,
    Details: [
      {
        fullName: "",
        email: "",
        phone: "",
        address: "",
        website: "",
        linkedin: "",
        github: "",
        summary: "",
        profileImage: "",
      },
    ],
    education: [
      {
        degree: "",
        location: "",
        fromDate: "",
        toDate: "",
      },
    ],
    experience: [
      {
        jobRole: "",
        companyName: "",
        companyLocation: "",
        fromDate: "",
        toDate: "",
        jobDescription: "",
      },
    ],
    skills: [
      {
        title: "",
        skillList: "",
      },
    ],
    achivawards: [
      {
        achivawards: "",
      },
    ],
    additionalInfo: [
      {
        addInfo: "",
      },
    ],

    projects: [
      {
        title: "",
        stack: "",
        desc: "",
      },
    ],
    profileImage: "",
    templateKey: "",
    tags: [],
    newTag:""
  });

  const [page, setPage] = useState(1)


  const formatDate = useCallback((dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = `${padTwoDigits(
        date.getMonth() + 1
      )}/${padTwoDigits(date.getDate())}/${date.getFullYear()} ${padTwoDigits(
        date.getHours()
      )}:${padTwoDigits(date.getMinutes())}`;
      return formattedDate;
    }
  }, []);

  const renderTemplate = (error, success) => {
    switch (selectedTemplate) {
      case "template1":
        return (
          <Template1 formData={formData} error={error} success={success} />
        );
      case "template2":
        return (
          <Template2 formData={formData} error={error} success={success} />
        );
      case "template3":
        return (
          <Template3 formData={formData} error={error} success={success} />
        );
      case "template4":
        return (
          <Template4 formData={formData} error={error} success={success} />
        );
      default:
        return (
          <Template1 formData={formData} error={error} success={success} />
        );
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/generate-summary/a-resumes?page=${page}`,
          { withCredentials: true }
        );

        setResumes(
          response?.data?.data.map((el, index) => ({
            count: index + 1,
            _id: el?._id,
            resumeTitle: el?.resumeTitle ?? "Untitled",
            tags:
              (el?.tags ?? []).length > 0
                ? el?.tags.map((tag) => ({
                    tag: tag || "Untitled",
                  }))
                : [],
            status: el?.status,
            templateKey: el?.templateKey,
            updatedBy: el?.updatedBy,
            updatedAt: formatDate(el?.updatedAt) ?? null,
            createdAt: formatDate(el?.createdAt) ?? null,
          }))
        );
      } catch (error) {
        console.error("Error fetching resumes:", error);
        const errorMessage = extractErrorMessage(error);
        setToastData({
          type: "error",
          message: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [setLoading, counter]);

  const handleToggleSwitch = (rowData) => {
    setShowConfirmationModal(true);
    console.log(rowData);
    setSelectResumeId(rowData?._id);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    // setResumeId("");
  };

  const handleConfirmation = async () => {
    setShowConfirmationModal(false);
    try {
      if (!selectResumeId) {
        setToastData({ message: "Resume ID is required.", type: "error" });
        return;
      }
      setBtnLoading(true);
      const response = await axios.post(
        `${API_URL}/api/v1/generate-summary/change-status`,
        { id: selectResumeId },
        { withCredentials: true }
      );
      if (!response?.data?.success) {
        setToastData({
          type: "error",
          message: response?.data?.message,
        });
        return;
      }
      setCounter((prev) => prev + 1);
      setToastData({
        type: "success",
        message: "Status changed successfully",
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      setToastData({
        type: "error",
        message: errorMessage,
      });
    } finally {
      setBtnLoading(false);
    }
  };

  const fetchAIResume = async (id) => {
    try {
      if (!id) {
        setToastData({ message: "Resume ID is required.", type: "error" });
        return;
      }

      const response = await axios.get(
        `${API_URL}/api/v1/generate-summary/preview-ai-resume`,
        {
          params: { id: id },
          withCredentials: true,
        }
      );

      if (!response?.data?.success) {
        setToastData({
          type: "error",
          message: response?.data?.message,
        });
        return;
      }
      return response;
    } catch (error) {
      console.log(error);
      const errorMessage = extractErrorMessage(error);
      setToastData({
        type: "error",
        message: errorMessage,
      });
    }
  };

  const handlePreviewResume = async (id) => {
    try {
      const response = await fetchAIResume(id);
      const item = response?.data?.data;
      if (!item) {
        setToastData({
          type: "error",
          message: "Resume not found.",
        });
        return;
      }

      setFormData({
        resumeId: item?.resumeId || "",
        resumeTitle: item?.resumeTitle || "Untitled",
        eduFlag: item?.eduFlag ?? true,
        workFlag: item?.workFlag ?? true,
        achFlag: item?.achFlag ?? true,
        addFlag: item?.addFlag ?? true,
        skillFlag: item?.skillsFlag ?? true,
        proFlag: item?.proFlag ?? true,
        Details: item?.personalDetails
          ? [
              {
                fullName: item?.personalDetails?.fullName || "",
                email: item?.personalDetails?.email || "",
                phone: item?.personalDetails?.phone || "",
                address: item?.personalDetails?.address || "",
                website: item?.personalDetails?.website || "",
                linkedin: item?.personalDetails?.linkedin || "",
                github: item?.personalDetails?.github || "",
                about: item?.personalDetails?.summary || "",
              },
            ]
          : [],
        education: item?.education || [],
        experience:
          (item?.experience ?? []).length > 0
            ? (item?.experience ?? []).map((exp) => ({
                jobRole: exp?.jobRole || "",
                companyName: exp?.companyName || "",
                companyLocation: exp?.companyLocation || "",
                fromDate: exp?.fromDate || "",
                toDate: exp?.toDate || "",
                jobDescription: exp?.responsibilities,
              })) || []
            : [],
        skills:
          (item?.skills ?? []).length > 0
            ? (item?.skills ?? []).map((skill) => ({
                title: skill?.title || "",
                skillList:
                  skill?.skillList.length > 0
                    ? skill?.skillList?.join(",") || ""
                    : "",
              })) || []
            : [],
        achivawards:
          (item?.achivawards ?? []).length > 0
            ? (item?.achivawards ?? []).map((achi) => ({
                achivawards: achi || "",
              }))
            : [],
        additionalInfo:
          (item?.additionalInfo ?? []).length > 0
            ? (item?.additionalInfo ?? []).map((info) => ({
                addInfo: info?.addInfo || "",
              })) || []
            : [],
        projects:
          (item?.projects ?? []).length > 0
            ? (item?.projects ?? []).map((proj) => ({
                title: proj?.title || "",
                stack:
                  proj?.stack?.length > 0 ? proj?.stack?.join(",") || "" : "",
                desc: proj?.desc ? proj?.desc.toString() : "",
              })) || []
            : [],
        templateKey: item?.templateKey,
      });
      setSelectedTemplate(item?.templateKey);
      setPreview(true);
    } catch (error) {
      console.log(error);
      setToastData({
        type: "error",
        message: "Failed to preview resume.",
      });
      return;
    }
  };

  const previewResume = (rowData) => {
    setSelectResumeId(rowData?._id);
    setSelectedTemplate(rowData?.templateKey);
    handlePreviewResume(rowData?._id);
  };

  //   const

  const handleEditResume = async (id) => {
    try {
      const response = await fetchAIResume(id);
      const item = response?.data?.data;
      if (!item) {
        setToastData({
          type: "error",
          message: "Resume not found.",
        });
        return;
      }
      setUpdateResume({
        resumeId: item?.resumeId || "",
        resumeTitle: item?.resumeTitle || "Untitled",
        eduFlag: item?.eduFlag ?? true,
        workFlag: item?.workFlag ?? true,
        achFlag: item?.achFlag ?? true,
        addFlag: item?.addFlag ?? true,
        skillFlag: item?.skillsFlag ?? true,
        proFlag: item?.proFlag ?? true,
        Details: item?.personalDetails
          ? [
              {
                fullName: item?.personalDetails?.fullName || "",
                email: item?.personalDetails?.email || "",
                phone: item?.personalDetails?.phone || "",
                address: item?.personalDetails?.address || "",
                website: item?.personalDetails?.website || "",
                linkedin: item?.personalDetails?.linkedin || "",
                github: item?.personalDetails?.github || "",
                summary: item?.personalDetails?.summary || "",
              },
            ]
          : [],
        education:
          (item?.education ?? []).length > 0
            ? (item?.education ?? []).map((edu) => ({
                degree: edu?.degree || "",
                location: edu?.location || "",
                fromDate: edu?.fromDate || "",
                toDate: edu?.toDate || "",
              })) || []
            : [],
        experience:
          (item?.experience ?? []).length > 0
            ? (item?.experience ?? []).map((exp) => ({
                jobRole: exp?.jobRole || "",
                companyName: exp?.companyName || "",
                companyLocation: exp?.companyLocation || "",
                fromDate: exp?.fromDate || "",
                toDate: exp?.toDate || "",
                jobDescription: exp?.responsibilities,
              })) || []
            : [],
        skills:
          (item?.skills ?? []).length > 0
            ? (item?.skills ?? []).map((skill) => ({
                title: skill?.title || "",
                skillList:
                  skill?.skillList.length > 0
                    ? skill?.skillList?.join(",") || ""
                    : "",
              })) || []
            : [],
        achivawards:
          (item?.achivawards ?? []).length > 0
            ? (item?.achivawards ?? []).map((achi) => ({
                achivawards: achi || "",
              }))
            : [],
        additionalInfo:
          (item?.additionalInfo ?? []).length > 0
            ? (item?.additionalInfo ?? []).map((info) => ({
                addInfo: info?.addInfo || "",
              })) || []
            : [],
        projects:
          (item?.projects ?? []).length > 0
            ? (item?.projects ?? []).map((proj) => ({
                title: proj?.title || "",
                stack:
                  proj?.stack?.length > 0 ? proj?.stack?.join(", ") || "" : "",
                desc: proj?.desc ? proj?.desc.toString() : "",
              })) || []
            : [],
        templateKey: item?.templateKey,
        tags: item?.tags,
      });
      setSelectedTemplate(item?.templateKey);
      setShowEditResume(true);
    } catch (error) {
      console.log(error);
      setToastData({
        type: "error",
        message: "Failed to edit resume.",
      });
      return;
    }
  };

  const editResume = (rowData) => {
    setSelectResumeId(rowData?._id);
    handleEditResume(rowData?._id);
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setUpdateResume((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleArrayChange = (e, index, fieldName, arrayName) => {
    const { name, value } = e.target;
    const updatedArray = [...updateResume[arrayName]];
    updatedArray[index][name] = value;
    setUpdateResume((prevState) => ({
      ...prevState,
      [arrayName]: updatedArray,
    }));
  };

  const handleAddNewItem = (arrayName, newItem) => {
    setUpdateResume((prevState) => ({
      ...prevState,
      [arrayName]: [...prevState[arrayName], newItem],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Submitted", updateResume);

    try {
      const details = updateResume.Details[0];
      const email = details.email;
      const fullName = details.fullName;
      const phone = details.phone;
      const about = details.summary;
      const resumeTitle = updateResume.resumeTitle;
      const linkedin = details.linkedin;
      const website = details.website;
      const github = details.github;

      const transformedWorkExperience = updateResume.experience.map((exp) => ({
        resumeId: selectResumeId,
        jobRole: exp.jobRole,
        companyName: exp.companyName,
        companyLocation: exp.companyLocation,
        fromDate: exp.fromDate,
        toDate: exp.toDate,
        responsibilities: exp.jobDescription,
      }));

      // Transform education data
      const transformedEducation = updateResume.education.map((edu) => ({
        resumeId: selectResumeId,
        degree: edu.degree,
        college: edu.college,
        location: edu.location,
        fromDate: edu.fromDate,
        toDate: edu.toDate,
      }));

      const transformedProjects = updateResume.projects.map((proj) => ({
        resumeId: selectResumeId,
        title: proj.title,
        stack: proj.stack,
        desc: proj.desc,
      }));

      // Transform skills data
      const transformedSkills = updateResume.skills.map((sk) => ({
        resumeId: selectResumeId,
        title: sk.title,
        skillList: sk.skillList,
      }));

      // Transform extra_info data
      const transformedExtraInfo = updateResume.additionalInfo.map((info) => ({
        addInfo: info?.addInfo,
      }));

      // achievement
      const transformedAchievements = updateResume?.achivawards.map(
        (el) => el?.achivawards
      );
      const saveResp = await axios.put(
        `${API_URL}/api/v1/generate-summary/update-resume`,
        {
          resumeId: selectResumeId,
          resumeTitle: resumeTitle,
          resumeTemplateImage: "",
          personalDetails: {
            fullName: fullName,
            email: email,
            phone: phone,
            website: website,
            linkedin: linkedin,
            github: github,
            summary: about,
          },
          education: transformedEducation,
          experience: transformedWorkExperience,
          skills: transformedSkills,
          achivawards: transformedAchievements,
          additionalInfo: transformedExtraInfo,
          projects: transformedProjects,
          eduFlag: updateResume.eduFlag,
          workFlag: updateResume?.workFlag,
          achFlag: updateResume?.achFlag,
          addFlag: updateResume?.addFlag,
          skillsFlag: updateResume?.skillFlag,
          proFlag: updateResume?.proFlag,
          templateKey: updateResume?.templateKey,
          tags: updateResume?.tags,
        },
        {
          withCredentials: true,
        }
      );

      if (saveResp?.data?.success) {
        setToastData({
          type: "success",
          message: "Resume updated successfully.",
        });
        setShowEditResume(false);
        setCounter((prev) => prev + 1);
        setUpdateResume({
          resumeId: "",
          resumeTitle: "",
          eduFlag: true,
          workFlag: true,
          achFlag: true,
          addFlag: true,
          skillFlag: true,
          proFlag: true,
          Details: [
            {
              fullName: "",
              email: "",
              phone: "",
              address: "",
              website: "",
              linkedin: "",
              github: "",
              summary: "",
              profileImage: "",
            },
          ],
          education: [
            {
              degree: "",
              location: "",
              fromDate: "",
              toDate: "",
            },
          ],
          experience: [
            {
              jobRole: "",
              companyName: "",
              companyLocation: "",
              fromDate: "",
              toDate: "",
              jobDescription: "",
            },
          ],
          skills: [
            {
              title: "",
              skillList: "",
            },
          ],
          achivawards: [
            {
              achivawards: "",
            },
          ],
          additionalInfo: [
            {
              addInfo: "",
            },
          ],

          projects: [
            {
              title: "",
              stack: "",
              desc: "",
            },
          ],
          profileImage: "",
          templateKey: "",
          tags: [],
        });
        return;
      } else {
        setToastData({
          type: "error",
          message: saveResp?.data?.message,
        });
      }
    } catch (error) {
      console.log(error);
      const errorHandler = extractErrorMessage(error);
      setToastData({
        type: "error",
        message: errorHandler,
      });
    }
  };

  const renderSwitch = (rowData) => (
    <div>
      <CustomSwitch
        checked={rowData.status === "active" ? true : false}
        onChange={() => handleToggleSwitch(rowData)}
        label={`Toggle switch for ${rowData?._id}`}
      />
    </div>
  );

  const renderActions = (rowData) => (
    <div className="flex items-center justify-center space-x-3">
      <div>
        <FaRegEdit
          className="text-red-500 cursor-pointer"
          onClick={() => {
            editResume(rowData);
          }}
        />
      </div>

      <div>
        <FaRegEye
          className="text-green-500 cursor-pointer"
          onClick={() => {
            previewResume(rowData);
          }}
        />
      </div>
    </div>
  );

  const headings = [
    { dataKey: "count", title: "Count" },
    { dataKey: "resumeTitle", title: "Resume Title" },
    { dataKey: "status", title: "Status" },
    { title: "Toggle Status", dataKey: "status", render: renderSwitch },
    {
      dataKey: "tags",
      title: "Tags",
      render: (row) => (
        <div className="flex gap-2 overflow-x-auto whitespace-nowrap">
          {row?.tags.map((tag, idx) => (
            <div
              key={idx}
              className="px-4 py-2 transition-all bg-gray-200 rounded-lg cursor-pointer hover:bg-primary hover:text-white"
            >
              {`${tag?.tag}`}
            </div>
          ))}
        </div>
      ),
    },
    { dataKey: "updatedBy", title: "Updated By" },
    { dataKey: "createdAt", title: "Created At" },
    { dataKey: "updatedAt", title: "Updated At" },
    { title: "Actions", dataKey: "id", render: renderActions },
  ];

  useEffect(() => {
    const updateScaleRatio = () => {
      const screenWidth = window.innerWidth;
      let newScaleRatio = 1; // Default ratio

      if (screenWidth <= 1024) {
        // Calculate scale ratio only if screen width is less than or equal to 1024
        newScaleRatio = screenWidth / 1090; // Adjust divisor as needed
      }

      setScaleRatio(newScaleRatio);
    };

    // Call the update function initially
    updateScaleRatio();

    // Update the scale ratio whenever the window is resized
    window.addEventListener("resize", updateScaleRatio);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", updateScaleRatio);
    };
  }, []);

  return (
    <div className="w-full px-2 mx-auto">
      <RoopTable
        loading={isLoading}
        data={resumes}
        itemsPerPage={10}
        headings={headings}
        hovered
        setPage={setPage}
      />

      <ReusableModal
        isOpen={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
        title="Are you sure ?"
        comments="By clicking yes the status of selected AI Resume will change."
      >
        <div className="flex justify-end space-x-4">
          <CButton
            loading={btnLoading}
            variant={ButtonType.Primary}
            onClick={handleConfirmation}
          >
            Yes
          </CButton>
        </div>
      </ReusableModal>

      <ReusableModal
        isOpen={preview}
        onClose={() => {
          setPreview(false);
          setFormData(null);
        }}
        width="w-full"
      >
        <div>
          <div>
            <div className="preview-modal">
              <div className="temp_in">
                <div
                  className="py-3 transformer"
                  style={{ transform: `scale(${scaleRatio})` }}
                >
                  <div className="preview-content">
                    <div
                      id="printArea"
                      className="border-[3px] border-gray-300 rounded-xl shadow-xl mb-4"
                    >
                      <PDFExport
                        paperSize="A4"
                        className="h-0.5"
                        fileName={"dhiraj"}
                        author="https://esumeBeen.com"
                      >
                        {renderTemplate()}
                      </PDFExport>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReusableModal>

      <ReusableModal
        isOpen={showEditResume}
        onClose={() => setShowEditResume(false)}
        title="Edit Resume"
        width="w-full"
      >
        <div className="max-h-[80vh] overflow-y-auto">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Resume Title */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Resume Title
              </label>
              <input
                type="text"
                name="resumeTitle"
                value={updateResume.resumeTitle}
                onChange={handleChange}
                className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>

            {/* Select Resume Template */}
            <div className="space-y-2">
              <label className="block text-sm font-medium">
                Select Template
              </label>
              <select
                name="templateKey"
                value={updateResume.templateKey}
                onChange={(e) =>
                  setUpdateResume({
                    ...updateResume,
                    templateKey: e.target.value,
                  })
                }
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              >
                <option value="template1">Template 1</option>
                <option value="template2">Template 2</option>
                <option value="template3">Template 3</option>
                <option value="template4">Template 4</option>
              </select>
            </div>

            {/* Tags Section */}
            <div className="space-y-2">
              <label className="block text-sm font-medium">Tags</label>
              <div className="flex items-center">
                <input
                  type="text"
                  placeholder="Add a tag"
                  value={updateResume.newTag || ""} 
                  onChange={(e) =>
                    setUpdateResume({ ...updateResume, newTag: e.target.value })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && updateResume.newTag) {
                      e.preventDefault(); 
                      setUpdateResume({
                        ...updateResume,
                        tags: [
                          ...(updateResume.tags || []),
                          updateResume.newTag,
                        ],
                        newTag: "", 
                      });
                    }
                  }}
                  className="block w-full px-3 py-2 mr-2 border border-gray-300 rounded-md shadow-sm"
                />
              </div>
              <div className="flex flex-wrap mt-2 space-x-2">
                {updateResume.tags?.map((tag, index) => (
                  <div
                    key={index}
                    className="flex items-center px-3 py-1 bg-gray-200 rounded-full"
                  >
                    <span>{tag}</span>
                    <button
                      type="button"
                      onClick={() => {
                        const updatedTags = updateResume.tags.filter(
                          (_, i) => i !== index
                        );
                        setUpdateResume({ ...updateResume, tags: updatedTags });
                      }}
                      className="ml-2 text-red-600 hover:text-red-800"
                    >
                      ✕
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Flags */}
            <div className="flex space-x-4">
              {[
                "eduFlag",
                "workFlag",
                "achFlag",
                "addFlag",
                "skillFlag",
                "proFlag",
              ].map((flag, index) => (
                <div key={index}>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      name={flag}
                      checked={updateResume[flag]}
                      onChange={handleChange}
                      className="form-checkbox"
                    />
                    <span className="ml-2">{flag}</span>
                  </label>
                </div>
              ))}
            </div>

            {/* Personal Details Section */}
            <div>
              <h3 className="text-lg font-medium">Personal Details</h3>
              {updateResume.Details.map((detail, index) => (
                <div key={index} className="space-y-2">
                  <div>
                    <label className="block text-sm font-medium">
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="fullName"
                      value={detail.fullName}
                      onChange={(e) =>
                        handleArrayChange(e, index, "fullName", "Details")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={detail.email}
                      onChange={(e) =>
                        handleArrayChange(e, index, "email", "Details")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Phone</label>
                    <input
                      type="text"
                      name="phone"
                      value={detail.phone}
                      onChange={(e) =>
                        handleArrayChange(e, index, "phone", "Details")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">LikedIn</label>
                    <input
                      type="text"
                      name="linkedin"
                      value={detail.linkedin}
                      onChange={(e) =>
                        handleArrayChange(e, index, "linkedin", "Details")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Github</label>
                    <input
                      type="test"
                      name="github"
                      value={detail.github}
                      onChange={(e) =>
                        handleArrayChange(e, index, "github", "Details")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Summary</label>
                    <input
                      type="test"
                      name="summary"
                      value={detail.summary}
                      onChange={(e) =>
                        handleArrayChange(e, index, "summary", "Details")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                </div>
              ))}
            </div>

            {/* Education Section */}
            <div>
              <h3 className="text-lg font-medium">Education</h3>
              {updateResume.education.map((edu, index) => (
                <div key={index} className="space-y-2">
                  <div>
                    <label className="block text-sm font-medium">Degree</label>
                    <input
                      type="text"
                      name="degree"
                      value={edu.degree}
                      onChange={(e) =>
                        handleArrayChange(e, index, "degree", "education")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium">
                      location
                    </label>
                    <input
                      type="text"
                      name="location"
                      value={edu.location}
                      onChange={(e) =>
                        handleArrayChange(e, index, "location", "education")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>

                  <div className="flex flex-col gap-1">
                    <label className="block text-sm font-medium">
                      from Date
                    </label>

                    <input
                      type="month"
                      name="fromDate"
                      value={edu.fromDate || ""}
                      onChange={(e) =>
                        handleArrayChange(e, index, "fromDate", "education")
                      }
                      placeholder="From Year"
                      className="w-full p-2 border rounded-xl"
                      onClick={(event) => event.stopPropagation()}
                    />
                    {edu.toDate === "Present" ? (
                      <input
                        type="text"
                        name="toDate"
                        value="Present"
                        readOnly
                        className="w-full p-2 bg-gray-200 border rounded-xl"
                        onClick={(event) => event.stopPropagation()}
                      />
                    ) : (
                      <>
                        <label className="block text-sm font-medium">
                          To Date
                        </label>
                        <input
                          type="month"
                          name="toDate"
                          value={edu?.toDate}
                          onChange={(e) =>
                            handleArrayChange(e, index, "toDate", "education")
                          }
                          placeholder="To Year"
                          className="w-full p-2 border rounded-xl"
                          onClick={(event) => event.stopPropagation()}
                        />
                      </>
                    )}
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={() =>
                  handleAddNewItem("education", {
                    degree: "",
                    college: "",
                    location: "",
                    fromDate: "",
                    toDate: "",
                  })
                }
                className="text-blue-500 hover:text-blue-700"
              >
                + Add Education
              </button>
            </div>

            {/* Experience Section */}
            <div>
              <h3 className="text-lg font-medium">Experience</h3>
              {updateResume.experience.map((exp, index) => (
                <div key={index} className="space-y-2">
                  <div>
                    <label className="block text-sm font-medium">
                      Job Role
                    </label>
                    <input
                      type="text"
                      name="jobRole"
                      value={exp.jobRole}
                      onChange={(e) =>
                        handleArrayChange(e, index, "jobRole", "experience")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium">
                      Company Name
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      value={exp.companyName}
                      onChange={(e) =>
                        handleArrayChange(e, index, "companyName", "experience")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium">
                      Company Location
                    </label>
                    <input
                      type="text"
                      name="companyLocation"
                      value={exp?.companyLocation}
                      onChange={(e) =>
                        handleArrayChange(
                          e,
                          index,
                          "companyLocation",
                          "experience"
                        )
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>

                  <div className="flex flex-col gap-1">
                    <label className="block text-sm font-medium">
                      from Date
                    </label>

                    <input
                      type="month"
                      name="fromDate"
                      value={exp?.fromDate || ""}
                      onChange={(e) =>
                        handleArrayChange(e, index, "fromDate", "experience")
                      }
                      placeholder="From Year"
                      className="w-full p-2 border rounded-xl"
                      onClick={(event) => event.stopPropagation()}
                    />
                    {exp?.toDate === "Present" ? (
                      <input
                        type="text"
                        name="toDate"
                        value="Present"
                        readOnly
                        className="w-full p-2 bg-gray-200 border rounded-xl"
                        onClick={(event) => event.stopPropagation()}
                      />
                    ) : (
                      <>
                        <label className="block text-sm font-medium">
                          To Date
                        </label>
                        <input
                          type="month"
                          name="toDate"
                          value={exp?.toDate}
                          onChange={(e) =>
                            handleArrayChange(e, index, "toDate", "experience")
                          }
                          placeholder="To Year"
                          className="w-full p-2 border rounded-xl"
                          onClick={(event) => event.stopPropagation()}
                        />
                      </>
                    )}
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={() =>
                  handleAddNewItem("experience", {
                    jobRole: "",
                    companyName: "",
                    location: "",
                    fromDate: "",
                    toDate: "",
                  })
                }
                className="text-blue-500 hover:text-blue-700"
              >
                + Add Experience
              </button>
            </div>

            {/* Skills Section */}
            <div>
              <h3 className="text-lg font-medium">Skills</h3>
              {updateResume.skills.map((skill, index) => (
                <div key={index} className="space-y-2">
                  <div>
                    <label className="block text-sm font-medium">
                      Skill Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={skill.title}
                      onChange={(e) =>
                        handleArrayChange(e, index, "title", "skills")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Skill List
                    </label>
                    <input
                      type="text"
                      name="skillList"
                      value={skill.skillList}
                      onChange={(e) =>
                        handleArrayChange(e, index, "skillList", "skills")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={() =>
                  handleAddNewItem("skills", { title: "", skillList: "" })
                }
                className="text-blue-500 hover:text-blue-700"
              >
                + Add Skill
              </button>
            </div>

            {/* Projects Section */}
            <div>
              <h3 className="text-lg font-medium">Projects</h3>
              {updateResume.projects.map((project, index) => (
                <div key={index} className="space-y-2">
                  <div>
                    <label className="block text-sm font-medium">
                      Project Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={project.title}
                      onChange={(e) =>
                        handleArrayChange(e, index, "title", "projects")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Project Stack
                    </label>
                    <input
                      type="text"
                      name="stack"
                      value={project.stack}
                      onChange={(e) =>
                        handleArrayChange(e, index, "stack", "projects")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Project Description
                    </label>
                    <textarea
                      name="desc"
                      value={project.desc}
                      onChange={(e) =>
                        handleArrayChange(e, index, "desc", "projects")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={() =>
                  handleAddNewItem("projects", {
                    title: "",
                    stack: [""],
                    desc: "",
                  })
                }
                className="text-blue-500 hover:text-blue-700"
              >
                + Add Project
              </button>
            </div>

            {/* Achievements and Awards Section */}
            <div>
              <h3 className="text-lg font-medium">Achievements & Awards</h3>
              {updateResume.achivawards.map((award, index) => (
                <div key={index} className="space-y-2">
                  <div>
                    <label className="block text-sm font-medium">
                      Achievement / Award
                    </label>
                    <input
                      type="text"
                      name="achivawards"
                      value={award.achivawards}
                      onChange={(e) =>
                        handleArrayChange(
                          e,
                          index,
                          "achivawards",
                          "achivawards"
                        )
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={() =>
                  handleAddNewItem("achivawards", { achivawards: "" })
                }
                className="text-blue-500 hover:text-blue-700"
              >
                + Add Achievement / Award
              </button>
            </div>

            {/* Additional Info Section */}
            <div>
              <h3 className="text-lg font-medium">Additional Information</h3>
              {updateResume.additionalInfo.map((info, index) => (
                <div key={index} className="space-y-2">
                  <div>
                    <label className="block text-sm font-medium">
                      Additional Information
                    </label>
                    <input
                      type="text"
                      name="addInfo"
                      value={info.addInfo}
                      onChange={(e) =>
                        handleArrayChange(e, index, "addInfo", "additionalInfo")
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={() =>
                  handleAddNewItem("additionalInfo", { addInfo: "" })
                }
                className="text-blue-500 hover:text-blue-700"
              >
                + Add Additional Info
              </button>
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                Save Resume
              </button>
            </div>
          </form>
        </div>
      </ReusableModal>
    </div>
  );
};

export default AIResume;
