import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Component/navbar";
import "./caursalPage.css";
import { API_URL } from "../apiConfig";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import Alert from "@mui/material/Alert";
import { PDFExport } from "@progress/kendo-react-pdf";
import Template1 from "../Component/template/Template1";
import Template2 from "../Component/template/Template2";
import Template3 from "../Component/template/Template3";
import Template4 from "../Component/template/Template4";
import Template6 from "../Component/template/Template6";
import Template7 from "../Component/template/Template7";
import Template8 from "../Component/template/Template8";
import Template9 from "../Component/template/Template9";
import Template10 from "../Component/template/Template10";
import Template11 from "../Component/template/Template11";
import Template12 from "../Component/template/Template12";
import Employee from "../image/Employee.png";
import planExpire from "../image/plan_expired.png";
import downloadingGif from "../image/downloading.gif";
import Footer from "../Component/footer.jsx";
import userStore from "../store/user.zustand.js";
import axios from "axios";
import { toast } from "react-toastify";
import resumeStore from "../store/resume.zustand.js";
import { CiEdit } from "react-icons/ci";
import download from "../image/dowload.svg";
import { IoMdCloudDone } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { LiaUniversitySolid } from "react-icons/lia";
import { CiLocationOn } from "react-icons/ci";
import { BsSuitcaseLg } from "react-icons/bs";
import { LuClock5 } from "react-icons/lu";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import { RiProfileLine } from "react-icons/ri";
import userProfileStore from "../store/user-profile.zustand.js";
import docLoading from "../image/doc_loading.gif";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import { FaRegFileWord } from "react-icons/fa6";
import angularParser from "angular-expressions";
import ImageModule from "docxtemplater-image-module";
import wordTemplate1 from "../image/word/template1.docx";
import wordTemplate2 from "../image/word/template2.docx";
import wordTemplate3 from "../image/word/template3.docx";
import wordTemplate4 from "../image/word/template4.docx";
import wordTemplate6 from "../image/word/template6.docx";
import wordTemplate7 from "../image/word/template7.docx";
import wordTemplate8 from "../image/word/template8.docx";
import wordTemplate9 from "../image/word/template9.docx";
import wordTemplate10 from "../image/word/template10.docx";
import wordTemplate11 from "../image/word/template11.docx";
import wordTemplate12 from "../image/word/template12.docx";
import resume7 from "../image/resume7.JPG"
import html2pdf from 'html2pdf.js';
import jsPDF from "jspdf";
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import ReactDOM from 'react-dom';
import { Helmet } from "react-helmet";
import ReactDOMServer from 'react-dom/server';
import { Document, Packer, Paragraph, TextRun } from "docx";


function Dashboard() {
  const navigate = useNavigate();
  // const  userStore((state) => state.user);
  const zustandResume = resumeStore((state) => state.resumes);
  const { user } = userStore();
  const zustandUserProfile = userProfileStore((state) => state.userProfile);
  // console.log('zustandResume:', zustandResume);
  // console.log('user:', user);
  // console.log('zustandUserProfile:', zustandUserProfile);
  // alert('zustandResume:', zustandResume);
  // alert('user:', user);
  // alert('zustandUserProfile:', zustandUserProfile);
  // const [dashboardData, setDashboardData] = useState(null);
  const [plan_expire, setPlan_expire] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const imageContainerRef = useRef(null);
  const [visibleMenuIndex, setVisibleMenuIndex] = useState(null);
  // const pdfExportComponent = useRef(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [editResumeTitleIcon, setEditResumeTitle] = useState({
    toggle: false,
    id: "",
    title: "",
  });
  const [reuseResumeLoading, setReuseResumeLoading] = useState(false);
  const pdfRefs = useRef([]);
  const prev = () => (imageContainerRef.current.scrollLeft -= 250);
  const next = () => (imageContainerRef.current.scrollLeft += 250);
  // 2. Open Resume
  const handleOpenResume = async (resume_id) => {
    if (user?.tokenDetails?.tokens > 0) {
      try {
        navigate(`/Template`, {
          state: {
            outputData: {
              ...user,
              resumeId: resume_id,
            },
          },
        });
      } catch (error) {
        console.error("Error:", error);

      }
    } else {
      setPlan_expire(true);
    }
  };
  // 3. Create Blanck Resume
  const handleCreateBlankResume = async () => {
    // Check credits validation as welll ( Need to check validation for if 0 credits left)
    if (user?.tokenDetails?.tokens >= 0) {
      try {
        // const authToken = localStorage.getItem("access");
        const createBlankResume = await axios.post(
          `${API_URL}/api/v1/resume/create-blank-resume`,
          {
            withCredentials: true,
          }
        );

        if (createBlankResume?.data?.success) {
          navigate(`/Template`, {
            state: {
              outputData: {
                ...user,
                resumeId: createBlankResume?.data?.data,
              },
            },
          });
          return;
        } else {
          toast.error(createBlankResume?.data?.message, {
            autoClose: 2500,
          });
          return;
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else if (user?.tokenDetails?.tokens <= 0) {
      // setPlan_expire(true);
    }
  };
  // 4. Create Resume by AI
  const handleCreateResumeWithAI = () => {
    if (user?.tokenDetails?.tokens > 0) {
      navigate("/create-resume");
    } else if (user?.tokenDetails?.tokens <= 0) {
      setPlan_expire(true);
    }
  };
  // 5. Create Resume by AI for JD
  const handleCreateResumeWithAIforJD = () => {
    if (user?.tokenDetails?.tokens > 0) {
      navigate("/create-resume-forjd");
    } else if (user?.tokenDetails?.tokens <= 0) {
      setPlan_expire(true);
    }
  };
  const handleCancel = () => {
    setPlan_expire(false);
  };
  const handleUpgrade = () => {
    navigate("/pricing");
  };
  // fetch all resumes *
  const fetchResumes = async () => {
    try {
      const page = 1;
      const resumesResponse = await axios.get(
        `${API_URL}/api/v1/resume/get-resumes`,
        {
          params: { page },
          withCredentials: true,
        }
      );
      if (resumesResponse?.data?.success) {
        resumeStore.getState().setResumes(resumesResponse?.data?.data);
        return;
      } else {
        if (
          resumesResponse?.data?.message === "Unauthorized" ||
          resumesResponse?.data?.message === "User not found"
        ) {
          navigate("/login", { replace: true });
        }
        toast.error(resumesResponse?.data?.message, {
          autoClose: 2500,
        });
        return;
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        autoClose: 2500,
      });
      return;
    }
  };
  useEffect(() => {
    // fetchUser();
    fetchResumes();
  }, []);
  // Export Resume
  const exportPDF = async (index) => {
    if (user?.tokenDetails?.tokens <= 1) {
      navigate("/pricing");
    } else {
      setVisibleMenuIndex(null);
      if (!isDownloading) {
        // Prevent multiple simultaneous downloads
        setIsDownloading(true); // Set isDownloading to true

        const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        // Wait for 2 seconds
        await wait(2000);

        if (pdfRefs.current[index]) {
          const content = ReactDOM.findDOMNode(pdfRefs.current[index]); // Get the actual DOM node

          // Log the content reference
          console.log("Content:", content);

          if (content instanceof HTMLElement) {
            // Log the content width
            const contentWidth = content.offsetWidth; // Get the width of the content in pixels
            console.log("Content Width (px):", contentWidth);

            // Define PDF options
            const options = {
              filename: `${user?.firstName}_Resume${new Date().getFullYear()}.pdf`, // Dynamic filename for the PDF
              image: { type: "jpeg", quality: 0.98 }, // Image quality settings
              html2canvas: { scale: 2 }, // Higher scale for better image quality
              jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }, // PDF size and orientation
              pagebreak: {
                mode: ["css"], // Use CSS mode for page breaks
                auto: true, // Enable automatic page breaks (do not require manual markers)
              },
            };

            // Log PDF dimensions
            const pdfWidth = options.jsPDF.format[0]; // Width of the PDF (in px)
            const pdfHeight = options.jsPDF.format[1]; // Height of the PDF (in px)
            console.log("PDF Dimensions (px):", { width: pdfWidth, height: pdfHeight });

            // Apply styles dynamically to the content
            const sectionTitles = content.querySelectorAll(".section-title");
            console.log("Section Titles:", sectionTitles);
            const headTexts = content.querySelectorAll(".headtext");
            headTexts.forEach((text) => {
              text.style.padding = "0 0 10px 0";
            });
            const pads = content.querySelectorAll(".downright");
             pads.forEach((text) => {
               text.style.padding="0 0 10px 30px";
             });
            const headTexts1 = content.querySelectorAll(".profile8");
            headTexts1.forEach((text) => {
              text.style.padding = "0 0 20px 0";
            });
            const headTexts2 = content.querySelectorAll(".profile9");
            headTexts2.forEach((text) => {
              text.style.padding = "0 0 20px 0";
            });
            const headTexts3 = content.querySelectorAll(".profile11");
            headTexts3.forEach((text) => {
              text.style.padding = "0 0 20px 0";
            });
            const profileimg8 = content.querySelectorAll(".profile-image8");
            profileimg8.forEach((img) => {
              img.style.height = "220px";
              img.style.width = "194px";
            });
            const sectionTitles9 = content.querySelectorAll(".section-title9");
            sectionTitles9.forEach((title) => {
              title.style.padding = "0 0 10px 0";
            });
            sectionTitles.forEach((title) => {
              title.style.padding = "0 0 10px 0";
              title.style.textAlign = "center";
              title.style.backgroundColor = "#002251";
              title.style.color = "#ffffff";
              title.style.fontWeight = "bold";
              title.style.fontSize = "16px";
              title.style.display = "flex";
              title.style.alignItems = "center";
              title.style.justifyContent = "center";
              title.style.pageBreakInside = "avoid";
              title.style.breakInside = "avoid";
            });
            const skill7Elements = content.querySelectorAll(".skill7");
            skill7Elements.forEach((skill) => {
              skill.style.color = "black";
              skill.style.display = "flex";
              skill.style.border = "1px solid #DAD6D5";
              skill.style.borderRadius = "10px";
              skill.style.padding = "0px 4px";
              skill.style.alignItems = "center";
              skill.style.textAlign = "center";
              skill.style.paddingBottom = "10px";
            });
            const sectionTitle3s = content.querySelectorAll(".section-title3");
            console.log("Section Title3s:", sectionTitle3s);
            sectionTitle3s.forEach((title) => {
              title.style.padding = "0px 0 10px 0";
              title.style.textAlign = "center";
              title.style.backgroundColor = "#ef5b00";
              title.style.color = "#ffffff";
              title.style.fontWeight = "bold";
              title.style.fontSize = "16px";
              title.style.display = "flex";
              title.style.alignItems = "center";
              title.style.justifyContent = "center";
              title.style.pageBreakInside = "avoid";
              title.style.breakInside = "avoid";
            });

            const sectionHeaders = content.querySelectorAll("section h2");
            console.log("Section Headers:", sectionHeaders);
            sectionHeaders.forEach((header) => {
              header.style.fontSize = "24px";
              header.style.color = "#333";
              header.style.marginBottom = "10px";
              header.style.borderBottom = "2px solid #ddd";
              header.style.paddingBottom = "15px";
            });

            // Log options before generating the PDF
            console.log("PDF Options:", options);

            // Use html2pdf to generate and download the PDF
            html2pdf().from(content).set(options).save();
          } else {
            console.error("Content is not a valid DOM element:", content);
          }
        } else {
          console.error("No content found for index:", index);
        }

        setIsDownloading(false); // Set isDownloading to false after download is complete
      }
    }
  };


  const toggleEditResumeTitle = (resumeId, currentTitle) => {
    setEditResumeTitle({
      toggle: !editResumeTitleIcon.toggle,
      id: resumeId,
      title: currentTitle || "",
    });
  };
  const updateResumeTitle = (e) => {
    setEditResumeTitle((prevState) => ({
      ...prevState,
      title: e.target.value,
    }));
  };
  const saveResumeTitle = async () => {
    if (!editResumeTitleIcon.id) {
      toast.error("Resume ID is required", { autoClose: 2500 });
      return;
    }

    if (!editResumeTitleIcon.title) {
      toast.error("Resume title is required", { autoClose: 2500 });
      return;
    }

    // set loader as well

    const response = await axios.put(
      `${API_URL}/api/v1/resume/update-resume`,
      {
        resumeId: editResumeTitleIcon.id,
        resumeTitle: editResumeTitleIcon.title,
      },
      { withCredentials: true }
    );

    // off the loader

    if (!response?.data?.success) {
      toast.error(response?.data?.message, { autoClose: 2500 });
      return;
    }

    const updateResumes = zustandResume?.map((el) => {
      if (el?.resumeId === editResumeTitleIcon?.id) {
        return { ...el, resumeTitle: editResumeTitleIcon?.title };
      }
      return el;
    });

    resumeStore.getState().setResumes(updateResumes);

    setEditResumeTitle({ toggle: false, id: "", title: "" });
  };
  const cancelEdit = () => {
    setEditResumeTitle({ toggle: false, id: "", title: "" });
  };
  const userProfile = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/users/get-profile`, {
        withCredentials: true,
      });

      if (!response?.data?.success) {
        toast.error(response?.data?.message, { autoClose: 2500 });
        return;
      }

      // Prepare and store the data in Zustand
      const userProfileData = {
        userProfile: {
          ...response?.data?.data,
          firstName: response?.data?.data?.user?.firstName,
          lastName: response?.data?.data?.user?.lastName,
          email: response?.data?.data?.user?.email,
          phone: response?.data?.data?.user?.phone,
        },
      };

      userProfileStore.getState().setUserProfile(userProfileData);

      // Extract the user ID from the stored data
      const userId = userProfileData.userProfile.user?._id;

      // Dynamically add the script
      const script = document.createElement('script');
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'signUp',
          'userId': '${userId}',
        });
      `;
      document.body.appendChild(script);
      console.log('User ID script added:', script.innerHTML);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    userProfile();
  }, []);
  function handlleUserProfileName() {
    if (
      zustandUserProfile?.userProfile?.firstName &&
      zustandUserProfile?.userProfile?.lastName
    ) {
      return `${zustandUserProfile?.userProfile?.firstName} ${zustandUserProfile?.userProfile?.lastName}`;
    } else if (zustandUserProfile?.userProfile?.firstName) {
      return `${zustandUserProfile?.userProfile?.firstName}`;
    } else if (zustandUserProfile?.userProfile?.lastName) {
      return `${zustandUserProfile?.userProfile?.lastName}`;
    } else {
      return `NA`;
    }
  }
  const reuseResumeFileChange = async (event) => {
    const reuseResumeFile = event.target.files[0]; // Get the file

    if (!reuseResumeFile) {
      toast.error("Please select a file to upload", { autoClose: 2500 });
      return;
    }

    // console.log("File selected:", reuseResumeFile); // Debugging

    document.getElementById("submitFormButton").click();
  };
  const handleReuseResumeUpload = async (e) => {
    e.preventDefault();

    const reuseResumeFile =
      document.getElementById("reuseResumeInput").files[0];

    if (!reuseResumeFile) {
      toast.error("Please select a file to upload", { autoClose: 2500 });
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", reuseResumeFile);

      setReuseResumeLoading(true);
      const response = await axios.post(
        `${API_URL}/api/v1/resume/resume-reuse`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      if (!response?.data?.success) {
        setReuseResumeLoading(false);
        toast.error(response?.data?.message, { autoClose: 2500 });
        return;
      }

      // Navigate after successful upload
      navigate(`/Template`, {
        state: {
          outputData: {
            ...user,
            resumeId: response?.data?.data,
          },
        },
      });
    } catch (error) {
      setReuseResumeLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2500 });
    }
  };
  const angularParserFunction = function (tag) {
    const expr = angularParser.compile(tag);
    return {
      get: function (scope) {
        return expr(scope);
      },
    };
  };
  const loadFile = (url, callback) => {
    JSZipUtils.getBinaryContent(url, callback);
  };
  const exportWord = async (formData) => {
    if (user?.tokenDetails?.tokens <= 1) {
      navigate("/pricing");
    } else {
      loadFile(
        `${formData?.templateKey === "template3" ? wordTemplate3 :
          formData?.templateKey === "template2" ? wordTemplate2 :
            formData?.templateKey === "template4" ? wordTemplate4 :
              formData?.templateKey === "template6" ? wordTemplate6 :
                formData?.templateKey === "template7" ? wordTemplate7 :
                formData?.templateKey === "template8" ? wordTemplate8 :
                formData?.templateKey === "template9" ? wordTemplate9 :
                formData?.templateKey === "template10" ? wordTemplate10 :
                formData?.templateKey === "template11" ? wordTemplate11 :
                formData?.templateKey === "template12" ? wordTemplate12 :
                 wordTemplate1
        }`,
        (error, content) => {
          if (error) {
            console.error("Error loading file:", error);
            throw error;
          }
  
          const zip = new PizZip(content);
  
          // Log the zip content before adding the image
          console.log("Initial ZIP content:", zip.files);
  
          // URL of the image to replace
          const imageToReplace = "word/media/image1.jpeg";  // Image in Word template
          const newImageURL = formData.Details[0]?.profileImage;  // URL from S3 or other source
  
          if (newImageURL) {
            // Only fetch the image if the new image URL is not empty
            fetch(newImageURL)
              .then((res) => {
                if (!res.ok) {
                  throw new Error(`Failed to fetch image from URL: ${newImageURL}`);
                }
                return res.blob();
              })
              .then((blob) => {
                console.log("Fetched image as Blob:", blob);
                const reader = new FileReader();
                reader.onloadend = () => {
                  console.log("FileReader finished loading the image");
  
                  // Log the image data before adding to ZIP
                  console.log("Image data (ArrayBuffer):", reader.result);
  
                  // Replace the image with the new one inside the ZIP
                  zip.file(imageToReplace, reader.result);
  
                  // Log the ZIP content after adding the image
                  console.log("ZIP content after adding the image:", zip.files);
  
                  // Proceed with setting the data for the Word document
                  const doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                  });
                  console.log("Additional Info:", formData.additionalInfo[0]?.addInfo);
                  // Set data for the document (including the image reference)
                  doc.setData({
                    fullName: formData.Details[0]?.fullName || "",
                    email: formData.Details[0]?.email || "",
                    phone: formData.Details[0]?.phone || "",
                    address: formData.Details[0]?.address || "",
                    website: formData.Details[0]?.website || "",
                    linkedin: formData.Details[0]?.linkedin || "",
                    github: formData.Details[0]?.github || "",
                    about: removeHtmlTags(formData.Details[0]?.about) || "",
                    profileImage: imageToReplace,  // Reference to the updated image
                    // Other fields for the Word document go here...
                    eduFlag: formData?.eduFlag ?? true,
                    workFlag: formData?.workFlag ?? true,
                    achFlag: formData?.achFlag ?? true,
                    addFlag: formData?.addFlag ?? true,
                    skillFlag: formData?.skillsFlag ?? true,
                    proFlag: formData?.proFlag ?? true,
                    education: safeMap(formData.education, (edu) => ({
                      degree: edu?.degree || "",
                      college: edu?.college || "",
                      location: edu?.location || "",
                      fromDate: edu?.fromDate || "",
                      toDate: edu?.toDate || "",
                    })),
                    experience: safeMap(formData.experience, (exp) => ({
                      jobRole: exp?.jobRole || "",
                      companyName: exp?.companyName || "",
                      companyLocation: exp?.companyLocation || "",
                      fromDate: exp?.fromDate || "",
                      toDate: exp?.toDate || "",
                      jobDescription: removeHtmlTags(
                        (exp?.jobDescription ?? []).length > 0 ? exp?.jobDescription?.join(",") : ""
                      ),
                    })),
                    skills: safeMap(formData.skills, (skill) => ({
                      title: skill?.title || "",
                      skillList: skill?.skillList || [],
                    })),
                    projects: safeMap(formData.projects, (pro) => ({
                      title: pro?.title || "",
                      stack: pro?.stack || "",
                      desc: removeHtmlTags(pro?.desc),
                    })),
                    achivawards: safeMap(formData.achivawards, (ach) => ({
                      achivawards: ach?.achivawards || "",
                    })),
                    additionalInfo: safeMap(formData.additionalInfo, (info) => ({
                      addinfodata: info?.addInfo || "",
                    })).map(info => info.addinfodata).join(", "),
                    resumeTitle: formData?.resumeTitle || "Untitled",
                  });
  
                  try {
                    doc.render();  // Render the template with the data
                  } catch (error) {
                    console.error("Error while rendering the document", error);
                  }
  
                  // Generate the Word document as a Blob
                  const output = doc.getZip().generate({
                    type: "blob",
                    mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  });
  
                  const wordFileName = formData.Details[0]?.fullName || "Untitled";
  
                  // Save the generated Word document
                  saveAs(output, `${wordFileName}.docx`);
                };
  
                reader.onerror = (error) => console.error("Error reading the image file", error);
                reader.readAsArrayBuffer(blob);  // Convert the image to ArrayBuffer
              })
              .catch((err) => {
                console.error("Error fetching the image", err);
              });
          } else {
            // If the URL is empty, we don't want to include any image in the document
            zip.remove(imageToReplace);
            const doc = new Docxtemplater(zip, {
              paragraphLoop: true,
              linebreaks: true,
            });
  
            // Set the data for the document without including the image
            doc.setData({
              fullName: formData.Details[0]?.fullName || "",
              email: formData.Details[0]?.email || "",
              phone: formData.Details[0]?.phone || "",
              address: formData.Details[0]?.address || "",
              website: formData.Details[0]?.website || "",
              linkedin: formData.Details[0]?.linkedin || "",
              github: formData.Details[0]?.github || "",
              about: removeHtmlTags(formData.Details[0]?.about) || "",
              profileImage: "",  // No image is set if URL is empty
              // Other fields for the Word document go here...
              eduFlag: formData?.eduFlag ?? true,
              workFlag: formData?.workFlag ?? true,
              achFlag: formData?.achFlag ?? true,
              addFlag: formData?.addFlag ?? true,
              skillFlag: formData?.skillsFlag ?? true,
              proFlag: formData?.proFlag ?? true,
              education: safeMap(formData.education, (edu) => ({
                degree: edu?.degree || "",
                college: edu?.college || "",
                location: edu?.location || "",
                fromDate: edu?.fromDate || "",
                toDate: edu?.toDate || "",
              })),
              experience: safeMap(formData.experience, (exp) => ({
                jobRole: exp?.jobRole || "",
                companyName: exp?.companyName || "",
                companyLocation: exp?.companyLocation || "",
                fromDate: exp?.fromDate || "",
                toDate: exp?.toDate || "",
                jobDescription: removeHtmlTags(
                  (exp?.jobDescription ?? []).length > 0 ? exp?.jobDescription?.join(",") : ""
                ),
              })),
              skills: safeMap(formData.skills, (skill) => ({
                title: skill?.title || "",
                skillList: skill?.skillList || [],
              })),
              projects: safeMap(formData.projects, (pro) => ({
                title: pro?.title || "",
                stack: pro?.stack || "",
                desc: removeHtmlTags(pro?.desc),
              })),
              achivawards: safeMap(formData.achivawards, (ach) => ({
                achivawards: ach?.achivawards || "",
              })),
              additionalInfo: safeMap(formData.additionalInfo, (info) => ({
                addinfodata: info?.addInfo || "",
              })),
              resumeTitle: formData?.resumeTitle || "Untitled",
            });
  
            try {
              doc.render();  // Render the template without the image
            } catch (error) {
              console.error("Error while rendering the document", error);
            }
  
            // Generate the Word document as a Blob
            const output = doc.getZip().generate({
              type: "blob",
              mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
  
            const wordFileName = formData.Details[0]?.fullName || "Untitled";
  
            // Save the generated Word document
            saveAs(output, `${wordFileName}.docx`);
          }
        }
      );
    }
  };
  
  



  
  // Helper functions
  function removeHtmlTags(htmlString) {
    if (htmlString) {
      const regex = /<\/?[^>]+(>|$)/g;
      return htmlString.replace(regex, "");
    }
    return ""; // Default empty string for undefined input
  }
  
  function safeMap(array, callback) {
    if (!Array.isArray(array)) return [];
    return array.map((item) => {
      try {
        return callback(item);
      } catch (err) {
        console.error("Error processing item:", item, err);
        return {}; // Default to an empty object if an error occurs
      }
    });
  }
  
  // Helper functions to handle data
  function removeHtmlTags(htmlString) {
    if (htmlString) {
      const regex = /<\/?[^>]+(>|$)/g;
      return htmlString.replace(regex, "");
    }
    return ""; // Default empty string for undefined input
  }
  
  function safeMap(array, callback) {
    if (!Array.isArray(array)) return [];
    return array.map((item) => {
      try {
        return callback(item);
      } catch (err) {
        console.error("Error processing item:", item, err);
        return {}; // Default to an empty object if an error occurs
      }
    });
  }
  
  // Helper functions to handle data
  function removeHtmlTags(htmlString) {
    if (htmlString) {
      const regex = /<\/?[^>]+(>|$)/g;
      return htmlString.replace(regex, "");
    }
    return ""; // Default empty string for undefined input
  }
  
  function safeMap(array, callback) {
    if (!Array.isArray(array)) return [];
    return array.map((item) => {
      try {
        return callback(item);
      } catch (err) {
        console.error("Error processing item:", item, err);
        return {}; // Default to an empty object if an error occurs
      }
    });
  }
  
  
  // Helper functions to handle data
  
  function removeHtmlTags(htmlString) {
    if (htmlString) {
      const regex = /<\/?[^>]+(>|$)/g;
      return htmlString.replace(regex, "");
    }
    return ""; // Default empty string for undefined input
  }
  
  function safeMap(array, callback) {
    if (!Array.isArray(array)) return [];
    return array.map((item) => {
      try {
        return callback(item);
      } catch (err) {
        console.error("Error processing item:", item, err);
        return {}; // Default to an empty object if an error occurs
      }
    });
  }



  return (
    <div className="flex flex-col min-h-screen bg-white">
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dashboard - ResumeBeen | Manage and Build Your Professional Resumes Effortlessly</title>
          <link rel="canonical" href="https://resumebeen.com/dashboard" />
          <meta property="og:site_name" content="ResumeBeen"></meta>
          <meta property="og:url" content="https://resumebeen.com/dashboard"></meta>
          <meta property="og:locale" content="en_EN"></meta>

          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta name="twitter:site" content="@resumebeen"></meta>
          <meta name="twitter:creator" content="@resumebeen"></meta>

          <meta property="og:type" content="website"></meta>

          <meta name="title" content="Dashboard - ResumeBeen | Manage and Build Your Professional Resumes Effortlessly"></meta>
          <meta property="og:title" content="Dashboard - ResumeBeen | Manage and Build Your Professional Resumes Effortlessly"></meta>


          <meta name="description" content="Welcome to your ResumeBeen Dashboard! Easily create, manage, and personalize your resumes and cover letters with AI-driven tools. Track your progress, access tailored templates, and build a standout resume in minutes."></meta>
          <meta property="og:description" content="Welcome to your ResumeBeen Dashboard! Easily create, manage, and personalize your resumes and cover letters with AI-driven tools. Track your progress, access tailored templates, and build a standout resume in minutes."></meta>

          <meta name="keywords" content="ResumeBeen dashboard, resume builder, AI resume tools, resume management, cover letter creator, job application tools, personalized resumes, professional resume templates, resume tracking, career advancement, resume analytics, resume customization, Matching Mode Analysis, resume progress tracking"></meta>
          <meta property="og:keywords" content="ResumeBeen dashboard, resume builder, AI resume tools, resume management, cover letter creator, job application tools, personalized resumes, professional resume templates, resume tracking, career advancement, resume analytics, resume customization, Matching Mode Analysis, resume progress tracking"></meta>

        </Helmet>
      </>

      {isDownloading && (
        <div className="download-overlay">
          <img
            src={downloadingGif}
            alt="Downloading"
            className="download-overlay-gif"
          />
          <span className="download-overlay-text">Downloading...</span>
        </div>
      )}
      {reuseResumeLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <img
            src={docLoading}
            alt="Loading..."
            className="w-[150px] h-[150px]"
          />
        </div>
      )}

      <Navbar />
      {plan_expire && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60">
          <div className="flex flex-col px-4 items-center w-[550px] h-[445px] rounded-2xl bg-white">
            <img src={planExpire} className="w-[50%]" alt="plan" />
            <p className="text-2xl font-semibold">
              Limit exceeded, No active plan.
            </p>
            <p className="mx-4 my-2 text-base text-center">
              You can download your resumes. Reactivate your plan to edit your
              resumes.
            </p>
            <div className="flex w-full gap-2 px-8 mx-4 mt-4">
              <button
                className="flex-1 py-2 text-sm font-semibold border-black rounded-md h-fit border-1"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="flex-1 py-2 text-sm font-semibold text-white bg-orange-500 rounded-md h-fit border-1"
                onClick={handleUpgrade}
              >
                Upgrade Plan
              </button>
            </div>
          </div>
        </div>
      )}
      {showAlert && (
        <Alert className="mx-32 mt-2" severity="warning">
          Maximum limit of resumes exceeded!
        </Alert>
      )}

      {/* New Dashboard */}

      {/* Small */}
      <div className="relative block mx-6 mt-12 overflow-hidden md:hidden lg:hidden bg-[#C0DDDD] rounded-md pt-4 pb-4 border-2 border-[#C0DDDD]">
        <div className="flex flex-col ">
          <div
            className="absolute h-[697px] w-[586px] rounded-full bg-gradient-to-b to-[#80FF88] from-[#80F7FF] opacity-40"
            style={{
              top: "-510px",
              left: "-381.19px",
              bottom: "-344px",
              transform: "rotate(624.13deg)",
            }}
          ></div>
          <div
            className="absolute h-[797px] w-[686px] rounded-full bg-gradient-to-b from-[#80F7FF] to-[#80FF88] opacity-40"
            style={{
              left: "--464px",
              bottom: "214px",
              transform: "rotate(261.87deg)",
            }}
          ></div>

          {/* <div className="flex items-center justify-center mt-2 mb-2">
            <button
              type="submit"
              className={`py-2 px-4 rounded-lg border-2 bg-white border-[#C0DDDD]  flex justify-center items-center space-x-2 font-semibold  text-sm leading-normal font-raleway space-x-2"`}
              onClick={() => navigate(`/profile?selectTab=edit-profile`)}
            >
              <RiProfileLine size={18} className="" />
              <p className="tracking-wide text-[#343636] font-montserrat font-medium leading-4 text-sm">
                Edit Profile
              </p>
            </button>
          </div> */}

          <div className="flex flex-col items-center justify-around space-y-4">
            <div className="flex flex-col items-center justify-center">
              <div className="relative flex flex-col items-center justify-center w-32 h-32 p-2 bg-white border-2 border-white rounded-full">
                <img
                  src={zustandUserProfile?.userProfile?.profilePic || Employee}
                  alt={Employee}
                  className="z-10 object-cover w-full h-full rounded-full"
                />
              </div>

              <div className="flex items-center justify-center mt-2 mb-2">
                <button
                  type="submit"
                  className={`py-2 px-4 rounded-lg border-2 bg-white border-[#C0DDDD]  flex justify-center items-center space-x-2 font-semibold  text-sm leading-normal font-raleway space-x-2"`}
                  onClick={() => navigate(`/profile?selectTab=edit-profile`)}
                >
                  <RiProfileLine size={18} className="" />
                  <p className="tracking-wide text-[#343636] font-montserrat font-medium leading-4 text-sm">
                    Edit Profile
                  </p>
                </button>
              </div>
            </div>

            <div className="flex flex-col space-y-6">
              <div className="flex flex-col items-center justify-start space-y-4">
                <div className="flex flex-col space-y-1">
                  <h2 className="text-2xl font-medium leading-7 tracking-normal font-montserrat">
                    {handlleUserProfileName()}
                  </h2>
                  <h3 className="text-sm font-medium leading-4 font-raleway text-[#343636]">
                    {zustandUserProfile?.userProfile?.designation || "NA"}
                  </h3>
                </div>
              </div>

              <div className="flex flex-col space-y-2">
                <div className="flex items-center justify-start space-x-2">
                  <div>
                    <LiaUniversitySolid className="text-[#878787]" />
                  </div>
                  <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3 capitalize">
                    {zustandUserProfile?.userProfile?.orgnization || "NA"}
                  </h3>
                </div>
                <div className="flex items-center justify-start space-x-2 ">
                  <div>
                    <CiLocationOn className="text-[#878787]" />
                  </div>
                  <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3">
                    {zustandUserProfile?.userProfile?.city || "NA"} |{" "}
                    {zustandUserProfile?.userProfile?.orgnizationWorkMode ||
                      "NA"}
                  </h3>
                </div>
              </div>

              <div className="flex flex-col space-y-2">
                <div className="flex items-center justify-start space-x-2">
                  <div>
                    <BsSuitcaseLg className="text-[#878787]" />
                  </div>
                  <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3 capitalize">
                    {zustandUserProfile?.userProfile?.totalExperience || "NA"}
                  </h3>
                </div>
                <div className="flex items-center justify-start space-x-2 ">
                  <div>
                    <LuClock5 className="text-[#878787]" />
                  </div>
                  <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3">
                    {zustandUserProfile?.userProfile?.noticePeriod || "NA"}
                  </h3>
                </div>
              </div>

              <div className="flex flex-col space-y-2">
                <div className="flex items-center justify-start space-x-2">
                  <div>
                    <FiPhone className="text-[#878787]" />
                  </div>
                  <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3">
                    {zustandUserProfile?.userProfile?.phone || "NA"}
                  </h3>
                </div>
                <div className="flex items-center justify-start space-x-2 ">
                  <div>
                    <MdOutlineMail className="text-[#878787]" />
                  </div>
                  <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3">
                    {zustandUserProfile?.userProfile?.email || "NA"}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Medium img */}
      <div className="lg:hidden md:block hidden  absolute z-20 top-[225px] left-40 right-20 flex justify-start w-32 h-32 p-2 border-2 rounded-full bg-white border-white">
        <img
          src={zustandUserProfile?.userProfile?.profilePic || Employee}
          alt={Employee}
          className="object-cover w-full h-full rounded-full"
        />
      </div>

      {/* Medium */}
      <div className="hidden relative overflow-hidden md:block lg:hidden mx-32 mt-12 bg-[#C0DDDD] rounded-md pt-4 pb-4 border-2 border-[#C0DDDD]">
        <div className="flex flex-col ">
          <div
            className="absolute h-[697px] w-[586px] rounded-full bg-gradient-to-b to-[#80FF88] from-[#80F7FF] opacity-40"
            style={{
              // top: "10px",
              left: "-270.19px",
              bottom: "-30px",
              transform: "rotate(86.13deg)",
            }}
          ></div>
          <div
            className="absolute h-[797px] w-[686px] rounded-full bg-gradient-to-b from-[#80F7FF] to-[#80FF88] opacity-40"
            style={{
              // top: "-40px",
              left: "-250.19px",
              bottom: "100px",
              transform: "rotate(-86.13deg)",
            }}
          ></div>

          <div className="flex justify-end mt-2 mb-2 mr-10">
            <button
              type="submit"
              className={`py-2 px-4 rounded-lg border-2 bg-white border-[#C0DDDD]  flex justify-center items-center space-x-2 font-semibold  text-sm leading-normal font-raleway space-x-2"`}
              onClick={() => navigate(`/profile?selectTab=edit-profile`)}
            >
              <RiProfileLine size={18} className="" />
              <p className="tracking-wide text-[#343636] font-montserrat font-medium leading-4 text-sm">
                Edit Profile
              </p>
            </button>
          </div>

          <div className="flex items-center justify-around">
            <div></div>

            <div className="flex flex-col space-y-6">
              <div className="flex items-center justify-between">
                <div className="flex flex-col space-y-2">
                  <div>
                    <h2 className="text-2xl font-medium leading-7 tracking-normal font-montserrat">
                      {handlleUserProfileName()}
                    </h2>
                    <h3 className="text-sm font-medium leading-4 font-raleway text-[#343636]">
                      {zustandUserProfile?.userProfile?.designation || "NA"}
                    </h3>
                  </div>
                </div>

                <div className="flex flex-col space-y-2">
                  <div className="flex items-center justify-center space-x-2">
                    <div>
                      <LiaUniversitySolid />
                    </div>
                    <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3">
                      {zustandUserProfile?.userProfile?.orgnization || "NA"}
                    </h3>
                  </div>
                  <div className="flex items-center justify-start space-x-2 ">
                    <div>
                      <CiLocationOn />
                    </div>
                    <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3">
                      {zustandUserProfile?.userProfile?.city || "NA"} |
                      {zustandUserProfile?.userProfile?.orgnizationWorkMode ||
                        "NA"}
                    </h3>
                  </div>
                </div>
              </div>

              <div className="flex justify-start space-x-4">
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center justify-start space-x-2">
                    <div>
                      <BsSuitcaseLg className="text-[#878787]" />
                    </div>
                    <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3 capitalize">
                      {zustandUserProfile?.userProfile?.totalExperience || "NA"}
                    </h3>
                  </div>
                  <div className="flex items-center justify-start space-x-2 ">
                    <div>
                      <LuClock5 className="text-[#878787]" />
                    </div>
                    <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3">
                      {zustandUserProfile?.userProfile?.noticePeriod || "NA"}
                    </h3>
                  </div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center justify-start space-x-2">
                    <div>
                      <FiPhone className="text-[#878787]" />
                    </div>
                    <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3">
                      {zustandUserProfile?.userProfile?.phone || "NA"}
                    </h3>
                  </div>
                  <div className="flex items-center justify-start space-x-2 ">
                    <div>
                      <MdOutlineMail className="text-[#878787]" />
                    </div>
                    <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3">
                      {zustandUserProfile?.userProfile?.email || "NA"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Long img */}
      <div className="hidden lg:block  absolute z-20 top-[250px] left-40 right-20 flex justify-start w-32 h-32 p-2 border-2 rounded-full bg-white border-white">
        <img
          src={zustandUserProfile?.userProfile?.profilePic || Employee}
          alt={Employee}
          className="object-cover w-full h-full rounded-full"
        />
      </div>

      {/* Large */}
      <div className="hidden relative mx-32 mt-12 mb-1 lg:block bg-[#C0DDDD] rounded-md pt-4 pb-4 border-2 border-[#C0DDDD] overflow-hidden">
        <div className="flex flex-col ">
          <span
            className="absolute h-[797px] w-[686px] rounded-full bg-gradient-to-b to-[#80FF88] from-[#80F7FF] opacity-40"
            style={{
              // top: "10px",
              left: "-270.19px",
              bottom: "-70px",
              transform: "rotate(86.13deg)",
            }}
          ></span>
          <span
            className="absolute h-[797px] w-[686px] rounded-full bg-gradient-to-b from-[#80F7FF] to-[#80FF88] opacity-40"
            style={{
              // top: "-40px",
              left: "-250.19px",
              bottom: "-120px",
              transform: "rotate(-86.13deg)",
            }}
          ></span>

          <div className="flex justify-end mt-6 mr-10">
            <button
              type="submit"
              className={`py-2 px-4 rounded-lg border-2 bg-white border-[#C0DDDD]  flex justify-center items-center space-x-2 font-semibold  text-sm leading-normal font-raleway space-x-2"`}
              onClick={() => navigate(`/profile?selectTab=edit-profile`)}
            >
              <RiProfileLine size={18} className="" />
              <p className="tracking-wide text-[#343636] font-montserrat font-medium leading-4 text-sm">
                Edit Profile
              </p>
            </button>
          </div>
          <div>
            <div className="relative flex flex-col space-y-4">
              <div className="flex items-center justify-around p-4">
                <div className="mx-6">
                  <div></div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div>
                    <h2 className="flex flex-wrap text-2xl font-medium leading-7 tracking-normal w-28 font-montserrat">
                      {handlleUserProfileName()}
                    </h2>
                    <h3 className="text-sm font-medium leading-4 font-raleway text-[#343636]">
                      {zustandUserProfile?.userProfile?.designation || "NA"}
                    </h3>
                  </div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center justify-start space-x-2">
                    <div>
                      <LiaUniversitySolid />
                    </div>
                    <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3">
                      {zustandUserProfile?.userProfile?.orgnization || "NA"}
                    </h3>
                  </div>
                  <div className="flex items-center justify-start space-x-2 ">
                    <div>
                      <CiLocationOn />
                    </div>
                    <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3">
                      {zustandUserProfile?.userProfile?.city || "NA"} |{" "}
                      {zustandUserProfile?.userProfile?.orgnizationWorkMode ||
                        "NA"}
                    </h3>
                  </div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center justify-start space-x-2">
                    <div>
                      <BsSuitcaseLg className="text-[#878787]" />
                    </div>
                    <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3 capitalize">
                      {zustandUserProfile?.userProfile?.totalExperience || "NA"}
                    </h3>
                  </div>
                  <div className="flex items-center justify-start space-x-2 ">
                    <div>
                      <LuClock5 className="text-[#878787]" />
                    </div>
                    <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3">
                      {zustandUserProfile?.userProfile?.noticePeriod || "NA"}
                    </h3>
                  </div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center justify-start space-x-2">
                    <div>
                      <FiPhone className="text-[#878787]" />
                    </div>
                    <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3">
                      {zustandUserProfile?.userProfile?.phone || "NA"}
                    </h3>
                  </div>
                  <div className="flex items-center justify-start space-x-2 ">
                    <div>
                      <MdOutlineMail className="text-[#878787]" />
                    </div>
                    <h3 className="text-[#343636] font-raleway font-medium text-sm leading-3">
                      {zustandUserProfile?.userProfile?.email || "NA"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dash_main_card relative mt-2 bg-[#C0DDDD] rounded-md min-h-[450px] mx-6 sm:mx-32 mb-16 sm:mb-16">
        <div className="dash_card_header flex text-4xl justify-between font-semibold px-4 pt-4 mx-3 text-[#1D6362]">
          Dashboard
          <button
            onClick={handleCreateResumeWithAI}
            className="text-lg font-medium text-white bg-[#1d6362] px-2 py-1 mr-2 rounded-md"
          >
            Create New
          </button>
        </div>

        <div className="grid grid-cols-1 mt-2 lg:grid-cols-2">
          <div className="mx-auto w-96">
            <div className="bg-white text-xl px-3 py-3 my-3 mx-3 rounded-2xl shadow-xl text-center text-[#1D6362] font-semibold">
              <button onClick={handleCreateResumeWithAI}>
                Create Resume with AI
              </button>
            </div>
            <div className="bg-white text-xl px-2 py-3 my-3 mx-3 rounded-2xl shadow-xl text-center text-[#1D6362] font-semibold">
              <button onClick={handleCreateBlankResume}>
                Create Blank Resume
              </button>
            </div>
            {/* <div className="bg-white text-xl px-2 py-3 my-3 mx-3 rounded-2xl shadow-xl text-center text-[#1D6362] font-semibold">
              Import from Linkedin
            </div> */}
            {/* <div className="bg-white text-xl px-2 py-3 my-3 mx-3 rounded-2xl shadow-xl text-center text-[#1D6362] font-semibold">
              <button onClick={handleCreateResumeWithAIforJD}>
                Create Resume for JD
              </button>
            </div> */}
            <div
              className="bg-white text-xl px-3 py-3 my-3 mx-3 rounded-2xl shadow-xl text-center text-[#1D6362] font-semibold cursor-pointer"
              onClick={() =>
                document.getElementById("reuseResumeInput").click()
              }
            >
              Re-use a Resume
            </div>
            <form onSubmit={handleReuseResumeUpload}>
              <input
                type="file"
                accept=".pdf"
                id="reuseResumeInput"
                onChange={reuseResumeFileChange}
                className="hidden"
              />
              <button type="submit" className="hidden" id="submitFormButton">
                Submit
              </button>
            </form>


          </div>

          {/* Resume component */}
          {zustandResume && (zustandResume ?? []).length > 0 ? (
            <div className="page-container">
              <div className="content">
                <div className="prev" onClick={prev}></div>
                <div className="slide-panel" ref={imageContainerRef}>
                  {/* Resume Map */}
                  {zustandResume &&
                    (zustandResume ?? []).map((item, index) => {
                      const formData = {
                        resumeId: item?.resumeId || "",
                        resumeTitle: item?.resumeTitle || "Untitled",
                        eduFlag: item?.eduFlag ?? true,
                        workFlag: item?.workFlag ?? true,
                        achFlag: item?.achFlag ?? true,
                        addFlag: item?.addFlag ?? true,
                        skillFlag: item?.skillsFlag ?? true,
                        proFlag: item?.proFlag ?? true,
                        Details: item?.personalDetails
                          ? [
                            {
                              fullName: item?.personalDetails?.fullName || "",
                              email: item?.personalDetails?.email || "",
                              phone: item?.personalDetails?.phone || "",
                              address: item?.personalDetails?.address || "",
                              website: item?.personalDetails?.website || "",
                              linkedin: item?.personalDetails?.linkedin || "",
                              github: item?.personalDetails?.github || "",
                              about: item?.personalDetails?.summary || "",
                              profileImage: item?.personalDetails?.profileImage || ""
                            },
                          ]
                          : [],
                        education: item?.education || [],
                        experience:
                          (item?.experience ?? []).map((exp) => {
                            let jobDescriptionHTML = "";
                            return {
                              jobRole: exp?.jobRole || "",
                              companyName: exp?.companyName || "",
                              companyLocation: exp?.companyLocation || "",
                              fromDate: exp?.fromDate || "",
                              toDate: exp?.toDate || "",
                              jobDescription: exp?.responsibilities,
                            };
                          }) || [],
                        skills:
                          (item?.skills ?? []).map((skill) => ({
                            title: skill?.title || "",
                            skillList: skill?.skillList?.join(", ") || "",
                          })) || [],
                        achivawards: (item?.achivawards ?? []).map((achi) => ({
                          achivawards: achi || "",
                        })),
                        additionalInfo:
                          (item?.additionalInfo ?? []).map((info) => ({
                            addInfo: info?.addInfo || "",
                          })) || [],
                        projects:
                          (item?.projects ?? []).map((proj) => {
                            return {
                              title: proj?.title || "",
                              stack: proj?.stack?.join(", ") || "",
                              desc: proj?.desc ? proj?.desc.toString() : "",
                            };
                          }) || [],
                        profileImage: "",
                        templateKey: item?.templateKey,
                      };
                      return (
                        <div key={index} className="slide-item">
                          <div className="relative w-full h-full mx-2 text-center bg-white rounded-2xl hover:shadow-xl">
                            <div
                              className="res_image_sec"
                              onClick={() => handleOpenResume(item.resumeId)}
                            >
                              <>
                                <PDFExport
                                  ref={(el) => (pdfRefs.current[index] = el)}
                                  paperSize="A4"
                                  fileName={
                                    item?.resumeTitle
                                      ? item?.resumeTitle
                                      : item?.resumeTitle === "Untitled"
                                        ? user?.firstName
                                        : user?.firstName +
                                        `_Resume${new Date().getFullYear()}`
                                  }
                                  author="https://ResumeBeen.com"
                                >
                                  <div
                                    className={`dash_in ${isDownloading ? "resume-visible" : ""
                                      }`}
                                  >
                                    <div className="dash_transformer">
                                      {item.templateKey === "template1" && (
                                        <Template1 formData={formData} />
                                      )}
                                      {item.templateKey === "template2" && (
                                        <Template2 formData={formData} />
                                      )}
                                      {item.templateKey === "template3" && (
                                        <Template3 formData={formData} />
                                      )}
                                      {item.templateKey === "template4" && (
                                        <Template4 formData={formData} />
                                      )}
                                      {item.templateKey === "template6" && (
                                        <Template6 formData={formData} />
                                      )}
                                      {item.templateKey === "template7" && (
                                        <Template7 formData={formData} />
                                      )}
                                       {item.templateKey === "template8" && (
                                        <Template8 formData={formData} />
                                      )}
                                       {item.templateKey === "template9" && (
                                        <Template9 formData={formData} />
                                      )}
                                      
                                       {item.templateKey === "template10" && (
                                        <Template10 formData={formData} />
                                      )}
                                       {item.templateKey === "template11" && (
                                        <Template11 formData={formData} />
                                      )}
                                       {item.templateKey === "template12" && (
                                        <Template12 formData={formData} />
                                      )}
                                      
                                    </div>
                                  </div>
                                </PDFExport>
                              </>
                            </div>

                            {/* Display Resume */}
                            <div className="flex items-center justify-between px-4 h-[15%] rounded-b-[16px] bg-white border-t border-dashed border-gray-400 relative">
                              {editResumeTitleIcon.toggle &&
                                editResumeTitleIcon.id === item.resumeId ? (
                                <input
                                  type="text"
                                  className="w-24 px-2 text-xs font-normal text-left text-gray-600 border border-gray-600 rounded"
                                  onChange={updateResumeTitle}
                                  value={editResumeTitleIcon.title}
                                />
                              ) : (
                                <p className="w-24 text-xs font-normal text-left text-gray-600 truncate">
                                  {item?.resumeTitle
                                    ? item.resumeTitle
                                    : "Untitled"}
                                </p>
                              )}
                              <div className="flex gap-x-4">
                                {editResumeTitleIcon.toggle &&
                                  editResumeTitleIcon.id === item.resumeId ? (
                                  <>
                                    <IoMdCloudDone
                                      onClick={saveResumeTitle}
                                      className="text-lg text-gray-500 cursor-pointer"
                                    />
                                    <RxCross2
                                      onClick={cancelEdit}
                                      className="text-lg text-red-500 cursor-pointer"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <CiEdit
                                      onClick={() =>
                                        toggleEditResumeTitle(
                                          item.resumeId,
                                          item.resumeTitle
                                        )
                                      }
                                      className="text-lg text-gray-500 cursor-pointer"
                                    />
                                    <img
                                      src={download}
                                      alt="download"
                                      className="text-lg cursor-pointer"
                                      onClick={() => exportPDF(index)}
                                    />
                                    <FaRegFileWord
                                      className="text-lg text-gray-500 cursor-pointer"
                                      onClick={() => exportWord(formData)}
                                    />
                                  </>
                                )}
                              </div>
                              {visibleMenuIndex === index && (
                                <div className="absolute right-0 z-10 w-40 mt-2 bg-white border rounded-md shadow-md bottom-10">
                                  <span
                                    onClick={() => exportPDF(index)}
                                    className="block px-4 py-2 text-sm text-gray-800 border-b cursor-pointer hover:bg-gray-200"
                                  >
                                    Download
                                  </span>
                                  <span
                                    onClick={() =>
                                      toggleEditResumeTitle(
                                        item.resumeId,
                                        item.resumeTitle
                                      )
                                    }
                                    className="block px-4 py-2 text-sm text-gray-800 cursor-pointer hover:bg-gray-200"
                                  >
                                    Rename
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="next" onClick={next}></div>
              </div>
              <div className="bottom_slider_btn">
                <div className="text-xl back" onClick={prev}>
                  <FaArrowAltCircleLeft />
                </div>
                <div className="text-xl righter" onClick={next}>
                  <FaArrowAltCircleRight />
                </div>
              </div>
            </div>
          ) : (
            <div className="relative w-4/5 mx-2 my-5">
              <div
                className="text-5xl font-semibold text-neutral-800"
                style={{ wordSpacing: "8px" }}
              >
                {
                  "Build your successful and professional resume instantly using AI"
                }
              </div>
              <section
                onClick={handleCreateResumeWithAI}
                className="flex self-start gap-3 px-6 py-2 my-6 text-white transition-colors duration-1000 border border-black bg-slate-700 w-60 rounded-xl hover:bg-orange-400 max-md:px-5 "
              >
                <p className="my-auto">Create AI resume</p>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/a74201ba4b9995e3135082ffa342eda686314d8dcf9e25310a286f8b8f4622f7?apiKey=e64c88474a2c4290b8b66f50b4a39c22&"
                  alt=""
                  className="w-8 shrink-0 aspect-square"
                />
              </section>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Dashboard;
