import React, { useEffect, useRef } from "react";
import "./Template12.css";

const Template12 = ({ formData }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const updateHeight = () => {
            const container = containerRef.current;
            if (container) {
                const containerWidth = container.offsetWidth;
                container.style.height = "auto"; // Allow height to adjust based on content
            }
        };
        updateHeight();
        window.addEventListener("resize", updateHeight);
        return () => {
            window.removeEventListener("resize", updateHeight);
        };
    }, []);

    // Safe access for formData and its properties
    const details = formData?.Details || []; // Default to an empty array if formData.Details is undefined
    const skills = formData?.skills || []; // Default to an empty array if formData.skills is undefined
    const education = formData?.education || []; // Default to an empty array if formData.education is undefined

    // Check for flags
    const skillFlag = formData?.skillFlag || false;
    const eduFlag = formData?.eduFlag || false;
    const workFlag = formData?.workFlag || false;

    return (
        <div className="template-container p-0 w-full">
            <div className="container p-0 m-0 w-full" ref={containerRef}>
                {/* Left Panel */}
                

                {/* Right Panel */}
                <div className="rightPanel12">
                    {details[0] && (
                        <div className="rightPanelfullname">
                            <h1>{details[0].fullName}</h1>
                            <h3 className="smallText">{details[0].jobTitle}</h3>
                        </div>
                    )}
                        {/* About Section */}
                        <div className="pad">
                            {details[0]?.about && (
                                <div>
                                
                                    <div className="smallText">
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: details[0].about,
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    
                        {/* Work Experience */}
                        {workFlag && (
                            <div className="section">
                                <div className="downright">
                                  <h2>Work experience</h2>
                                </div>
                                <div className="pad">
                                    {formData?.experience &&
                                        formData?.experience.map((exp, index) => (
                                            <div key={index} className="section-content">
                                                <p className="bold-text">
                                                    {exp.jobRole} {(exp?.jobRole && exp?.companyLocation) ? "at" : ""} {exp.companyName}
                                                </p>
                                                <p>{exp.companyLocation}</p>
                                                <p>
                                                    {exp?.fromDate || ""} {(exp?.fromDate && exp?.toDate) ? "-" : ""} {exp?.toDate || ""}
                                                </p>
                                                <p dangerouslySetInnerHTML={{ __html: exp.jobDescription }} />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                            {formData.proFlag && (
                            <div className="experience">
                                    {/* {formData.projects.length !== 0 ? ( */}
                                    <div className="downright">
                                     <h2 className="acheiv">Project Developed Or Handled</h2>
                                    </div>
                                    {/* ) : null} */}
                                    <div className="pad">
                                        {formData?.projects &&
                                        formData?.projects.map((prodit, index) => (
                                            <div key={index} className="section-content">
                                            <h3 className="bold-text pt-2 pb-2">
                                                {prodit.title} {prodit?.title && prodit?.stack ? "-" : ""}{" "}
                                                {prodit.stack}
                                            </h3>
                                            <p dangerouslySetInnerHTML={{ __html: prodit.desc }} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                )}
                            {formData.addFlag && (
                                <div className="experience">
                                <div className="downright">
                                   <h2>Additional Information</h2>
                                </div>
                                <div className="pad">
                                    {formData?.additionalInfo &&
                                    formData?.additionalInfo.map((addinfo, index) => (
                                        <div key={index} className="section-content">
                                        <p>{addinfo.addInfo}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            )}
                 
                </div>
                <div className="leftPanel12">

                <div className="profileimg12">
                                {formData.Details &&
                                    formData.Details.map((detail, index) => (
                                        detail.profileImage ? (
                                            <img
                                                key={index}
                                                src={detail.profileImage}
                                                alt="Profile"
                                                className="profile-image12"
                                            />
                                        ) : null
                                    ))
                                }
                            </div>
                    {/* Contact Section */}
                    <div className="item bottomLineSeparator">
                        <h2>CONTACT</h2>
                        <div className="smallText">
                            {details.map((detail, index) => (
                                <React.Fragment key={index}>
                                    <div>
                                        <p>
                                            <i className="fa fa-phone contactIcon" aria-hidden="true"></i>
                                            {detail.phone}
                                        </p>
                                        <p>
                                            <i className="fa fa-envelope contactIcon" aria-hidden="true"></i>
                                            <a href={`mailto:${detail.email}`}>{detail.email}</a>
                                        </p>
                                        <p>
                                            <i className="fa fa-map-marker contactIcon" aria-hidden="true"></i>
                                            {detail.address}
                                        </p>
                                        {detail.linkedin && (
                                            <p>
                                                <i className="fa fa-linkedin-square contactIcon" aria-hidden="true"></i>
                                                <a href={detail.linkedin}>{detail.linkedin}</a>
                                            </p>
                                        )}
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>

                    {/* Skills Section */}
                    {skillFlag && (
                        <div className="item bottomLineSeparator">
                            <h2>SKILLS</h2>
                            <div className="smallText">
                                {formData?.skills.map((skill, index) => (
                                    <div className="skill" key={index}>
                                        <div>
                                        <span className="bold-text">{`${skill?.title ? `${skill?.title} - `: ""}`}</span>
                                            {skill.skillList}
                                        </div>
                                        <div className="yearsOfExperience">
                                            <span>{skill.years}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Education Section */}
                    {eduFlag && (
                        <div className="item">
                            <h2>EDUCATION</h2>
                            <div className="smallText">
                                {education.map((edu, index) => (
                                    <div key={index}>
                                        <p className="bolded white">{edu.degree}</p>
                                        <p>{edu.college}</p>
                                        <p>
                                            {edu.fromDate} - {edu.toDate}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    
                {formData.achFlag && (
                <div className="experience">
                    {/* {formData.achivawards.length !== 0 ? ( */}
                    <h2 className="acheiv">Achievements & Awards</h2>
                    {/* ) : null} */}
                    {formData?.achivawards &&
                    formData?.achivawards.map((ach, index) => (
                        <div key={index} className="section-content">
                        <p>{ach.achivawards}</p>
                        </div>
                    ))}
                </div>
                )}

                </div>
            </div>
        </div>
    );
};

export default Template12;




