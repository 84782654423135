import React, { useEffect, useRef } from "react";
import "./Template7.css";

const Template7 = ({ formData }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const updateHeight = () => {
            const container = containerRef.current;
            if (container) {
                const containerWidth = container.offsetWidth;
                container.style.height = "auto"; // Allow height to adjust based on content
            }
        };
        updateHeight();
        window.addEventListener("resize", updateHeight);
        return () => {
            window.removeEventListener("resize", updateHeight);
        };
    }, []);

    // Safe access for formData and its properties
    const details = formData?.Details || []; // Default to an empty array if formData.Details is undefined
    console.log("details", details);
    const skills = formData?.skills || []; // Default to an empty array if formData.skills is undefined
    const education = formData?.education || []; // Default to an empty array if formData.education is undefined

    // Check for flags
    const skillFlag = formData?.skillFlag || false;
    const eduFlag = formData?.eduFlag || false;
    const workFlag = formData?.workFlag || false;

    return (
        <div className="template-container p-0 w-full" ref={containerRef}>
            <div className="orangebg">
                {details[0] && (
                    <div className="header-profile">
                        <div className="profilename basis-2/3">
                            <h1 style={{ fontSize: '42px', color: '#fff' }}>{details[0].fullName}</h1>

                            <div className="smallTexttext">
                                {details.map((detail, index) => (
                                    <React.Fragment key={index}>
                                        <div>
                                            <p>
                                                <i className="fa fa-phone contactIcon" aria-hidden="true"></i>
                                                {detail.phone}
                                            </p>
                                            <p>
                                                <i className="fa fa-envelope contactIcon" aria-hidden="true"></i>
                                                <a href={`mailto:${detail.email}`}>{detail.email}</a>
                                            </p>
                                            <p>
                                                <i className="fa fa-map-marker contactIcon" aria-hidden="true"></i>
                                                {detail.address}
                                            </p>
                                            {detail.linkedin && (
                                                <p>
                                                    <i className="fa fa-linkedin-square contactIcon" aria-hidden="true"></i>
                                                    <a href={detail.linkedin}>{detail.linkedin}</a>
                                                </p>
                                            )}
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div className="profileimg7 basis-1/3">
                            {formData.Details &&
                                formData.Details.map((detail, index) => {
                                    console.log("Detail Object: ", detail); // This will log the detail object to the console
                                    return detail.profileImage ? (
                                        <img
                                            key={index}
                                            src={detail.profileImage}
                                            alt="Profile"
                                            className="profile-image7"
                                        />
                                    ) : null;
                                })
                            }
                        </div>
                    </div>
                )}

            </div>
            <div className="container p-0 m-0 w-full">
                {/* Left Panel */}
                <div className="leftPanel7">
                    {/* Contact Section7 */}
                    <div className="item ">
                        <div className="bottomLineSeparator">
                            {details[0]?.about && (
                                <div>
                                    <h2>About me</h2>
                                    <div className="smallText7">
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: details[0].about,
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Skills Section7 */}


                    {/* Education Section7 */}
                    {eduFlag && (
                        <div className="item bottomLineSeparator">
                            <h2>EDUCATION</h2>
                            <div className="smallText7">
                                {education.map((edu, index) => (
                                    <div key={index}>
                                        <p className="bolded white">{edu.degree}</p>
                                        <p>{edu.college}</p>
                                        <p>
                                            {edu.fromDate} - {edu.toDate}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {formData.proFlag &&
                        <div className="item">
                            {/* {formData.projects.length !== 0 ?(  */}
                            <h2>Project Developed Or Handled</h2>
                            {/* ):(null)} */}
                            {formData?.projects && formData?.projects.map((prodit, index) => (
                                <div key={index} className="smallText7 proddesc">
                                    <p className="bold-text">{prodit.title} {(prodit.title && prodit.stack) ? "-" : ""} {prodit.stack}</p>
                                    <p dangerouslySetInnerHTML={{ __html: prodit.desc }} />
                                </div>
                            ))}
                        </div>
                    }
                            {formData.achFlag && (
                        <div className="experience">
                            {/* {formData.achivawards.length !== 0 ? ( */}
                            <h2 className="acheiv">Achievements & Awards</h2>
                            {/* ) : null} */}
                            {formData?.achivawards &&
                            formData?.achivawards.map((ach, index) => (
                                <div key={index} className="section-content">
                                <p>{ach.achivawards}</p>
                                </div>
                            ))}
                        </div>
                        )}
                </div>

                {/* Right Panel */}
                <div className="rightPanel7">



                    {skillFlag && (
                        <div className="item bottomLineSeparator">
                            <h2>SKILLS</h2>
                            <div className="smallText7">
                                {formData?.skills.map((skill, index) => (
                                    <div className="skill7" key={index}>
                                        <div>
                                        <span className="bold-text">{`${skill?.title ? `${skill?.title} - `: ""}`}</span>
                                            {skill.skillList}
                                        </div>
                                        <div className="yearsOfExperience">
                                            <span>{skill.years}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {/* Work Experience */}
                    {workFlag && (
                        <div className="section7">
                            <h2>Work experience</h2>
                            {formData?.experience &&
                                formData?.experience.map((exp, index) => (
                                    <div key={index} className="section-content">
                                        <p className="bold-text">
                                            {exp.jobRole} {(exp?.jobRole && exp?.companyLocation) ? "at" : ""} {exp.companyName}
                                        </p>
                                        <p>{exp.companyLocation}</p>
                                        <p>
                                            {exp?.fromDate || ""} {(exp?.fromDate && exp?.toDate) ? "-" : ""} {exp?.toDate || ""}
                                        </p>
                                        <p dangerouslySetInnerHTML={{ __html: exp.jobDescription }} />
                                    </div>
                                ))}
                        </div>
                    )}
                    {formData.addFlag && (
                    <div className="experience">
                        <h2>Additional Information</h2>
                        {formData?.additionalInfo &&
                        formData?.additionalInfo.map((addinfo, index) => (
                            <div key={index} className="section-content">
                            <p>{addinfo.addInfo}</p>
                            </div>
                        ))}
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Template7;




