import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../Component/navbar";
import { API_URL } from "../apiConfig.js";
import DynamicPersonalDetail from "../Component/DynamicPersonalDetail.jsx";
import DynamicEducationInputs from "../Component/DynamicEducationInputs";
import DynamicExperienceInputs from "../Component/DynamicExperienceInputs";
import DynamicSkillsInputs from "../Component/DynamicSkillsInputs";
import DynamicAddInfos from "../Component/DymanicAddInfos";
import DynamicAchivAward from "../Component/DynamicAchivAward";
import DynamicProject from "../Component/DynamicProject";
import menu from "../image/menu.png";
import pencil from "../image/pencil.png";
import portfolio from "../image/portfolio.png";
import project from "../image/project.png";
import trophy from "../image/trophy.png";
import userPic from "../image/user.png";
import graduation from "../image/graduation-cap.png";
import { PDFExport } from "@progress/kendo-react-pdf";
import html2pdf from 'html2pdf.js';
import {
  FaSave,
  FaTrash,
  FaDownload,
  FaEye,
  FaSpinner,
} from "react-icons/fa";
import jsPDF from "jspdf";
import {
  BsCloudCheckFill,
  BsExclamationTriangleFill,
  BsArrowsFullscreen,
} from "react-icons/bs";
import Toggle from "../Component/Toggle.jsx";
import TemplateSelector from "./TemplateSelector.jsx";
import Template1 from "../Component/template/Template1";
import Template2 from "../Component/template/Template2";
import Template3 from "../Component/template/Template3";
import Template4 from "../Component/template/Template4";
import Template6 from "../Component/template/Template6";
import Template7 from "../Component/template/Template7";
import Template8 from "../Component/template/Template8";
import Template9 from "../Component/template/Template9";
import Template10 from "../Component/template/Template10";
import Template11 from "../Component/template/Template11";
import Template12 from "../Component/template/Template12";
import "./ResumeBuilder.css";
import html2canvas from "html2canvas";
import downloadingGif from "../image/downloading.gif";
import userStore from "../store/user.zustand.js";
import { Helmet } from "react-helmet";


const arrayToString = (array) => {
  if (!Array.isArray(array)) return array; // If it's not an array, return as it is
  return array.join(", ");
};

const ResumeBuilder = ({ template, fetchDatas }) => {
  const containerRef = useRef(null); // Reference to the content you want to capture
  const [isDownloading, setIsDownloading] = useState(false);
  const navigate = useNavigate();
  const { user } = userStore();
  const [showPreview, setShowPreview] = useState(false);
  // const [isDownloading, setIsDownloading] = useState(false);

  //template selector
  const [selectedTemplate, setSelectedTemplate] = useState("");
  // State to track whether data has been saved successfully
  const [dataSaved, setDataSaved] = useState(false);
  const [aiToken, setAiToken] = useState(
    fetchDatas?.outputData?.available_token || 0
  );
  const prevFormData = useRef(null);

  const [formData, setFormData] = useState({
    resumeId: fetchDatas.outputData.resumeId,
    resumeTitle: fetchDatas.outputData?.resumeTitle,
    eduFlag: fetchDatas.outputData?.eduFlag,
    workFlag: fetchDatas.outputData?.workFlag,
    achFlag: fetchDatas.outputData?.achFlag,
    addFlag: fetchDatas.outputData?.addFlag,
    skillFlag: fetchDatas.outputData?.skillsFlag,
    proFlag: fetchDatas.outputData?.proFlag,
    Details: [
      {
        fullName: fetchDatas?.outputData?.personalDetails?.fullName || fetchDatas?.outputData?.firstName || "",
        email: fetchDatas?.outputData?.personalDetails?.email || fetchDatas?.outputData?.email || "",
        phone: fetchDatas?.outputData?.personalDetails?.phone || fetchDatas?.outputData?.phone || "",
        address: fetchDatas?.outputData?.personalDetails?.address,
        website: fetchDatas?.outputData?.personalDetails?.website,
        linkedin: fetchDatas?.outputData?.personalDetails?.linkedin || "",
        github: fetchDatas?.outputData?.personalDetails?.github || "",
        about: fetchDatas?.outputData?.personalDetails?.summary || "",
        profileImage: fetchDatas?.outputData?.personalDetails?.profileImage || ""
      },
    ],
    education: (fetchDatas?.outputData?.education || []).map((edu) => ({
      degree: edu.degree || "",
      college: edu.college || "",
      location: edu.location || "",
      fromDate: edu.fromDate || "",
      toDate: edu.toDate || "",
    })),
    experience: (fetchDatas?.outputData?.experience || []).map((exp) => {
      let jobDescriptionHTML = "";
      // if (Array.isArray(exp.responsibilities)) {
      //   jobDescriptionHTML = exp.responsibilities
      //     .map((responsibility) => `<li>${responsibility}</li>`)
      //     .join("");
      //   jobDescriptionHTML = `<ul>${jobDescriptionHTML}</ul>`;
      // } else {
      //   jobDescriptionHTML = exp.responsibilities;
      // }

      return {
        jobRole: exp.jobRole || "",
        companyName: exp.companyName || "",
        companyLocation: exp.companyLocation || "",
        fromDate: exp.fromDate || "",
        toDate: exp.toDate || "",
        jobDescription: exp?.responsibilities,
      };
    }),
    skills: (fetchDatas?.outputData?.skills || []).map((skill) => ({
      title: skill.title || "",
      skillList: arrayToString(skill.skillList) || "",
    })),
    achivawards: (fetchDatas?.outputData?.achivawards || []).map(
      (achiv) => ({
        achivawards: achiv || "",
      })
    ),
    additionalInfo: (fetchDatas?.outputData?.additionalInfo || []).map(
      (info) => ({
        addInfo: info.addInfo,
      })
    ),

    projects: (fetchDatas?.outputData?.projects || []).map((proj) => {
      let descriptionHTML = "";
      // if (Array.isArray(proj.desc)) {
      //   descriptionHTML = proj.desc
      //     .map((desc) => `<li>${desc}</li>`)
      //     .join("");
      //   descriptionHTML = `<ul>${descriptionHTML}</ul>`;
      // } else {
      //   descriptionHTML = proj.desc;
      // }

      return {
        title: proj.title || "",
        stack: arrayToString(proj.stack) || "",
        desc: proj?.desc ? proj?.desc.toString() : "",
      };
    }),
    profileImage: "",
    templateKey: template
  });

  const [showPersonalDetails, setShowPersonalDetails] = useState(true);
  const [showEducation, setShowEducation] = useState(false);
  const [showExperience, setShowExperience] = useState(false);
  const [showSkills, setShowSkills] = useState(false);
  const [showAchivAwards, setShowAchivAwards] = useState(false);
  const [showaddInfos, setShowaddInfos] = useState(false);
  const [showProjects, setShowProjects] = useState(false);

  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const intervalRef = useRef(null);

  const captureRef = useRef(null);
  const pdfExportComponent = useRef(null);

  // edu
  const [visible1, setVisible1] = useState(fetchDatas.outputData?.eduFlag);
  // exp
  const [visible2, setVisible2] = useState(fetchDatas.outputData?.workFlag);
  // skills
  const [visible3, setVisible3] = useState(fetchDatas.outputData?.skillsFlag);
  // Project developed
  const [visible4, setVisible4] = useState(fetchDatas.outputData?.proFlag);
  // achievements
  const [visible5, setVisible5] = useState(fetchDatas.outputData?.achFlag);
  // additional info
  const [visible6, setVisible6] = useState(fetchDatas.outputData?.addFlag);

  const [score, setScore] = useState(0);
  const [scaleRatio, setScaleRatio] = useState(1);

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };


  const processExperienceData = () => {
    const experienceData = fetchDatas?.outputData?.experience || []; // Ensure experienceData is an array

    const experience = experienceData.map((exp) => {
      let jobDescriptionHTML = "";
      // if (Array.isArray(exp.responsibilities)) {
      //   jobDescriptionHTML = exp.responsibilities
      //     .map((responsibility) => `<li>${responsibility}</li>`)
      //     .join("");
      //   jobDescriptionHTML = `<ul>${jobDescriptionHTML}</ul>`;
      // } else {
      //   jobDescriptionHTML = exp.responsibilities;
      // }

      // console.log({exp:jobDescriptionHTML})

      jobDescriptionHTML = exp?.responsibilities.toString()

      // console.log({jobDescriptionHTML})

      return {
        jobRole: exp.jobRole || "",
        companyName: exp.companyName || "",
        companyLocation: exp.companyLocation || "",
        fromDate: exp.fromDate || "", // Assuming fromDate is the property for the start year
        toDate: exp.toDate || "", // Assuming toDate is the property for the end year
        jobDescription: jobDescriptionHTML,
      };
    });

    setFormData((prevState) => ({
      ...prevState,
      experience: experience,
    }));
  };

  const processProjectData = () => {
    const addprojectData = fetchDatas?.outputData?.projects || [];

    const projects = addprojectData.map((proj) => {
      let descriptionHTML = "";
      // if (Array.isArray(proj.desc)) {
      //   descriptionHTML = proj.desc
      //     .map((desc) => `<li>${desc}</li>`)
      //     .join("");
      //   descriptionHTML = `<ul>${descriptionHTML}</ul>`;
      // } else {
      //   descriptionHTML = proj.desc;
      // }

      descriptionHTML = proj?.desc.toString()

      return {
        title: proj.title || "",
        stack: arrayToString(proj.stack) || "",
        desc: descriptionHTML,
      };
    });

    setFormData((prevState) => ({
      ...prevState,
      projects: projects,
    }));
  };

  const processEducationData = () => {
    const educationData = fetchDatas?.outputData?.education || [];

    const education = educationData.map((edu) => ({
      degree: edu.degree || "",
      college: edu.college || "",
      location: edu.location || "",
      fromDate: edu.fromDate || "",
      toDate: edu.toDate || "",
    }));
    setFormData((prevState) => ({
      ...prevState,
      education: education,
    }));
  };

  const processSkillsData = () => {
    const addSkillsData = fetchDatas?.outputData?.skills || [];

    const skills = addSkillsData.map((skill) => ({
      title: skill.title || "",
      skillList: arrayToString(skill.skillList) || "",
    }));
    setFormData((prevState) => ({
      ...prevState,
      skills: skills,
    }));
  };

  const processAddInfoData = () => {
    const addInfoData = fetchDatas?.outputData?.additionalInfo || [];

    const addInfo = addInfoData.map((info) => ({
      addInfo: info.addInfo,
    }));

    setFormData((prevState) => ({
      ...prevState,
      additionalInfo: addInfo,
    }));
  };

  const processAchivmentData = () => {
    const addAchivmentData = fetchDatas?.outputData?.achivawards || [];

    const Achivmentdata = addAchivmentData.map((addAchiv) => ({
      achivawards: addAchiv || "",
    }));
    setFormData((prevState) => ({
      ...prevState,
      achivawards: Achivmentdata,
    }));
  };

  useEffect(() => {
    processExperienceData();
    processEducationData();
    processProjectData();
    processSkillsData();
    processAddInfoData();
    processAchivmentData();
  }, []);


  const handleScreenshot = () => {
    const element = captureRef.current;
    if (element) {
      html2canvas(element)
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const link = document.createElement("a");
          link.href = imgData;
          link.download = "screenshot.png";
          link.click();
        })
        .catch((err) => {
          console.error("Screenshot capture failed:", err);
        });
    }
  };
  const captureResumeImage = async () => {
    const printArea = document.getElementById("printArea");
    const canvas = await html2canvas(printArea);
    const imageData = canvas.toDataURL("image/png");
    return imageData;
  };

  const saveFormData = async () => {
    // console.log("Prev data", prevFormData.current);
    try {
      if (dataSaved) {
        console.log('Data unchanged. Skipping API call.');
        setDataSaved(true); // Data is already saved
        return;
      }
      setIsSaving(true); // Show loader
      setError(null);

      // const resumeImageData = await captureResumeImage();

      const details = formData.Details[0];

      // Access fields from the details object
      const email = details.email;
      const fullName = details.fullName;
      const phone = details.phone;
      const address = details.address;
      const about = details.about;
      const resumeTitle = formData.resumeTitle;
      const linkedin = details.linkedin
      const website = details.website
      const github = details.github

      const transformedHandleLinks = formData.Details.flatMap((detail) => [
        {
          resumeId: fetchDatas.outputData.resumeId,
          handle_name: "LinkedIn",
          link: detail.linkedin,
        },
        {
          resumeId: fetchDatas.outputData.resumeId,
          handle_name: "GitHub",
          link: detail.github,
        },
        {
          resumeId: fetchDatas.outputData.resumeId,
          handle_name: "Website",
          link: detail.website,
        },
      ]);

      const transformedWorkExperience = formData.experience.map((exp) => ({
        resumeId: fetchDatas.outputData.resumeId,
        jobRole: exp.jobRole,
        companyName: exp.companyName,
        companyLocation: exp.companyLocation,
        fromDate: exp.fromDate,
        toDate: exp.toDate,
        responsibilities: exp.jobDescription,
      }));

      // Transform education data
      const transformedEducation = formData.education.map((edu) => ({
        resumeId: fetchDatas.outputData.resumeId,
        degree: edu.degree,
        college: edu.college,
        location: edu.location,
        fromDate: edu.fromDate,
        toDate: edu.toDate,
      }));

      const transformedProjects = formData.projects.map((proj) => ({
        resumeId: fetchDatas.outputData.resumeId,
        title: proj.title,
        stack: proj.stack,
        desc: proj.desc,
      }));

      // Transform skills data
      const transformedSkills = formData.skills.map((sk) => ({
        resumeId: fetchDatas.outputData.resumeId,
        title: sk.title,
        skillList: sk.skillList,
      }));

      // Transform extra_info data
      const transformedExtraInfo = formData.additionalInfo.map((info) => ({
        addInfo: info?.addInfo,
      }));

      // achievement
      const transformedAchievements = formData?.achivawards.map((el) => el?.achivawards)
      const saveResp = await axios.put(`${API_URL}/api/v1/resume/update-resume`, {
        resumeId: fetchDatas.outputData.resumeId,
        resumeTitle: resumeTitle,
        resumeTemplateImage: "",
        personalDetails: {
          fullName: fullName,
          email: email,
          phone: phone,
          website: website,
          linkedin: linkedin,
          github: github,
          summary: about,
          address: address,
          profileImage: details?.profileImage
        },
        education: transformedEducation,
        experience: transformedWorkExperience,
        skills: transformedSkills,
        achivawards: transformedAchievements,
        additionalInfo: transformedExtraInfo,
        socialLinks: transformedHandleLinks,
        projects: transformedProjects,
        eduFlag: formData.eduFlag,
        workFlag: formData?.workFlag,
        achFlag: formData?.achFlag,
        addFlag: formData?.addFlag,
        skillsFlag: formData?.skillsFlag,
        proFlag: formData?.proFlag,
        templateKey: selectedTemplate || fetchDatas?.outputData?.templateKey
      }, {
        withCredentials: true,
      });


      if (saveResp?.data?.success) {
        setSaveStatus("success");
        setDataSaved(true);
        prevFormData.current = formData;
      } else {
        setError("Failed to save data.");
      }

      // Check if the request was successful
      // if (response.status === 200) {
      //   setDataSaved(true); // Data saved successfully
      //   prevFormData.current = formData; // Update the reference to the latest formData
      //   setSaveStatus("success");
      // } else {
      //   setError("Failed to save data.");
      // }
      // console.log("*******")
      // console.log(formData)
    } catch (error) {
      console.error("Error saving data:", error);
      setDataSaved(false); // Data saving failed
      setSaveStatus("failed");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const startAutoSave = () => {
      intervalRef.current = setInterval(() => {
        saveFormData();
      }, 5000);
    };

    startAutoSave();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [dataSaved, selectedTemplate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setDataSaved(false);
  };

  const handleSaveClick = async () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear the current interval
    }
    await saveFormData(); // Save data instantly
    intervalRef.current = setInterval(saveFormData, 30000); // Restart the autosave interval
  };

  const handleReset = () => {
    setFormData({
      resumeId: fetchDatas.outputData.resumeId,
      // resumeTitle: fetchDatas.outputData?.resumeTitle,
      eduFlag: true,
      workFlag: true,
      achFlag: true,
      addFlag: true,
      skillFlag: true,
      proFlag: true,
      Details: [
        {
          fullName: "",
          email: "",
          phone: "",
          address: "",
          website: "",
          linkedin: "",
          github: "",
          about: "",
        },
      ],
      education: [
        { degree: "", college: "", location: "", fromDate: "", toDate: "" },
      ],
      experience: [
        {
          jobRole: "",
          companyName: "",
          companyLocation: "",
          fromDate: "",
          toDate: "",
          jobDescription: "",
        },
      ],
      skills: [{ title: "", skillList: "" }],
      achivawards: [{ achivawards: "" }],
      additionalInfo: [{ addInfo: "" }],
      projects: [{ title: "", stack: "", desc: "" }],
      profileImage: "",
    });
    setVisible1(true);
    setVisible2(true);
    setVisible3(true);
    setVisible4(true);
    setVisible5(true);
    setVisible6(true);
    setDataSaved(true);
    // saveFormData()
  };


  // Function to handle PDF export
  const handleDownloadPDF = async () => {
  console.log("current_user_tokens : ",user?.tokenDetails?.tokens );    
    if (user?.tokenDetails?.tokens <= 1) {
      navigate("/pricing");
    } else {
      const content = containerRef.current;
  
      // Check if the content exists
      if (!content) {
        console.error("Content not found!");
        return;
      }
  
      const dynamicFileName = `${formData?.Details[0]?.fullName}_Resume${new Date().getFullYear()}`;
  
      try {
        setIsDownloading(true);
        console.log("Starting download process...");
        const headTexts = content.querySelectorAll(".headtext");
          headTexts.forEach((text) => {
            text.style.padding="0 0 10px 0";
          });
        const pads = content.querySelectorAll(".downright");
          pads.forEach((text) => {
            text.style.padding="0 0 10px 30px";
          });
        const headTexts1 = content.querySelectorAll(".profile8");
          headTexts1.forEach((text) => {
            text.style.padding="0 0 20px 0";
          });
          const headTexts3 = content.querySelectorAll(".profile11");
            headTexts3.forEach((text) => {
              text.style.padding = "0 0 20px 0";
            });
        const profileimg8 = content.querySelectorAll(".profile-image8");
        profileimg8.forEach((img) => {
          img.style.height = "220px";
          img.style.width = "194px";
        });
        const headTexts2 = content.querySelectorAll (".profile9");
            headTexts2.forEach((text) => {
              text.style.padding = "0 0 20px 0";
            });
        // Dynamically adjust the styles of .section-title
        const sectionTitles = content.querySelectorAll(".section-title");
        sectionTitles.forEach((title) => {
          title.style.padding = "0 0 10px 0";
          title.style.textAlign = "center";
          title.style.backgroundColor = "#002251";
          title.style.color = "#ffffff";
          title.style.fontWeight = "bold";
          title.style.fontSize = "16px";
          title.style.display = "flex";
          title.style.alignItems = "center";
          title.style.justifyContent = "center";
          title.style.pageBreakInside = "avoid";
          title.style.breakInside = "avoid";
        });
        const sectionTitles9 = content.querySelectorAll(".section-title9");
        sectionTitles9.forEach((title) => {
          title.style.padding = "0 0 10px 0";
        });
  
        // Dynamically adjust the styles of .section-title3
        const sectionTitle3s = content.querySelectorAll(".section-title3");
        sectionTitle3s.forEach((title) => {
          title.style.padding = "0px 0 10px 0";
          title.style.textAlign = "center";
          title.style.backgroundColor = "#ef5b00";
          title.style.color = "#ffffff";
          title.style.fontWeight = "bold";
          title.style.fontSize = "16px";
          title.style.display = "flex";
          title.style.alignItems = "center";
          title.style.justifyContent = "center";
          title.style.pageBreakInside = "avoid";
          title.style.breakInside = "avoid";
        });
  
        // Dynamically adjust the styles of section h2
        const sectionHeaders = content.querySelectorAll("section h2");
        sectionHeaders.forEach((header) => {
          header.style.fontSize = "24px";
          header.style.color = "#333";
          header.style.marginBottom = "10px";
          header.style.borderBottom = "2px solid #ddd";
          header.style.paddingBottom = "15px";
        });
  
        // Dynamically adjust the styles of .skill7
        const skill7Elements = content.querySelectorAll(".skill7");
        skill7Elements.forEach((skill) => {
          skill.style.color = "black";
          skill.style.display = "flex";
          skill.style.border = "1px solid #DAD6D5";
          skill.style.borderRadius = "10px";
          skill.style.padding = "0px 4px";
          skill.style.alignItems = "center";
          skill.style.textAlign = "center";
          skill.style.paddingBottom = "10px";
        });
  
        // Define the options for PDF generation, with conditional margin for template 6
        const options = {
          filename: `${dynamicFileName}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 4 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          pagebreak: {
            mode: ["css"],
            auto: true,
          },
          margin: selectedTemplate === "template6" ? [0, 0, 0, 0] : [0, 0,0, 0],
        };
  
        // Apply the options to the content and generate the PDF
        await html2pdf()
          .from(content)
          .set(options)
          .save();
  
        console.log("PDF download complete.");
      } catch (error) {
        console.error("Failed to generate PDF:", error);
      } finally {
        setIsDownloading(false);
        window.location.reload();
      }
    }
  };
  


  useEffect(() => {
    let template_keyer;
    // if (fetchDatas?.outputData?.resume_draft?.templateKey) {
    //   template_keyer = fetchDatas.outputData.resume_draft.templateKey;
    // } else if (fetchDatas?.outputData?.templateKey) {
    //   console.log({ templateKey: fetchDatas?.outputData?.templateKey })
    //   template_keyer = fetchDatas.outputData.templateKey;
    // }

    console.log({ template_keyer });

    const template = fetchDatas?.outputData?.templateKey;
    console.log('Setting selected template:', template); // Log the selected template value
    setSelectedTemplate(template); // Set initial selected template
  }, []); // Empty dependency array to run effect only once on mount


  const renderTemplate = (error, success) => {
    switch (selectedTemplate) {
      case "template1":
        return <Template1 formData={formData} error={error} success={success} />;
      case "template2":
        return <Template2 formData={formData} error={error} success={success} />;
      case "template3":
        return <Template3 formData={formData} error={error} success={success} />;
      case "template4":
        return <Template4 formData={formData} error={error} success={success} />;
      case "template6":
        return <Template6 formData={formData} error={error} success={success} />;
        case "template7":
          return <Template7 formData={formData} error={error} success={success} />;
          case "template8":
          return <Template8 formData={formData} error={error} success={success} />;
          case "template9":
          return <Template9 formData={formData} error={error} success={success} />;
          case "template10":
          return <Template10 formData={formData} error={error} success={success} />;
          case "template11":
          return <Template11 formData={formData} error={error} success={success} />;
          case "template12":
          return <Template12 formData={formData} error={error} success={success} />;
      default:
        return <p>Please select a template to start building your resume.</p>;
    }
  };



  const toggleEdu = () => {
    setVisible1(!visible1);
    // console.log("Current value of 'visible1':", !visible1);
    setFormData({ ...formData, eduFlag: !formData.eduFlag });
  };

  const toggleWorkExp = () => {
    setVisible2(!visible2);
    // console.log("Current value of 'visible2':", !visible2);
    setFormData({ ...formData, workFlag: !formData.workFlag });
  };

  const toggleSkills = () => {
    setVisible3(!visible3);
    // console.log("Current value of 'visible3':", !visible3);
    setFormData({ ...formData, skillFlag: !formData.skillFlag });
  };
  const toggleProject = () => {
    setVisible4(!visible4);
    // console.log("Current value of 'visible4':", !visible4);
    setFormData({ ...formData, proFlag: !formData.proFlag });
  };
  const toggleachievements = () => {
    setVisible5(!visible5);
    // console.log("Current value of 'visible4':", !visible5);
    setFormData({ ...formData, achFlag: !formData.achFlag });
  };
  const toggleadditions = () => {
    setVisible6(!visible6);
    // console.log("Current value of 'visible6':", !visible6);
    setFormData({ ...formData, addFlag: !formData.addFlag });
  };
  // console.log(fetchDatas.outputData.name);


  useEffect(() => {
    const updateScaleRatio = () => {
      const screenWidth = window.innerWidth;
      let newScaleRatio = 1; // Default ratio

      if (screenWidth <= 1024) {
        // Calculate scale ratio only if screen width is less than or equal to 1024
        newScaleRatio = screenWidth / 1090; // Adjust divisor as needed
      }

      setScaleRatio(newScaleRatio);
    };

    // Call the update function initially
    updateScaleRatio();

    // Update the scale ratio whenever the window is resized
    window.addEventListener("resize", updateScaleRatio);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", updateScaleRatio);
    };
  }, []);

  useEffect(() => {
    saveFormData();
  }, []);


  useEffect(() => {
    const calculateScore = () => {
      let totalScore = 0;

      // Personal Details
      const personalDetails = formData.Details[0];
      if (personalDetails) {
        const { fullName, phone, address, github, linkedin, website } =
          personalDetails;
        if (
          fullName ||
          phone ||
          address ||
          github ||
          linkedin ||
          website
        ) {
          totalScore += 2;
        }
        if (personalDetails.email) {
          totalScore += 5;
        }
        if (personalDetails.about) {
          totalScore += 15;
        }
      }

      // Experience
      if (formData.experience.length > 0) {
        totalScore += 30;
      }

      // Education
      if (formData.education.length > 0) {
        totalScore += 10;
      }

      // Skills
      if (formData.skills.length > 0) {
        totalScore += 15;
      }

      // Additional Info
      if (formData.additionalInfo.length > 0) {
        totalScore += 7;
      }

      // Awards and Achievements
      if (formData.achivawards.length > 0) {
        totalScore += 6;
      }

      setScore(Math.round(totalScore));
      setDataSaved(false); // Set dataSaved to false when the score changes
    };

    calculateScore();
  }, [formData]);

  useEffect(() => {
    const scoreElement = document.getElementById("progress_bar");
    if (scoreElement) {
      scoreElement.style.width = `${score}%`;
    }
  }, [score]);

  return (
    <div className="bg-[#f8fcfd]">
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Resume Builder - ResumeBeen | Create Your Professional Resume in Minutes</title>
          <link rel="canonical" href="https://resumebeen.com/" />
          <meta property="og:site_name" content="ResumeBeen"></meta>
          <meta property="og:url" content="https://resumebeen.com/"></meta>
          <meta property="og:locale" content="en_EN"></meta>

          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta name="twitter:site" content="@resumebeen"></meta>
          <meta name="twitter:creator" content="@resumebeen"></meta>

          <meta property="og:type" content="website"></meta>

          <meta name="title" content="Resume Builder - ResumeBeen | Create Your Professional Resume in Minutes"></meta>
          <meta property="og:title" content="Resume Builder - ResumeBeen | Create Your Professional Resume in Minutes"></meta>


          <meta name="description" content="Manage your ResumeBeen profile to access personalized resume-building tools, track your progress, and update your career information. Customize your experience and build a professional resume that showcases your strengths."></meta>
          <meta property="og:description" content="Manage your ResumeBeen profile to access personalized resume-building tools, track your progress, and update your career information. Customize your experience and build a professional resume that showcases your strengths."></meta>

          <meta name="keywords" content="ResumeBeen resume builder, create resume, professional resume builder, AI resume builder, customizable resume templates, personalized resume, resume creation tools, career-oriented resume, ATS-friendly resume, resume optimization, cover letter builder"></meta>
          <meta property="og:keywords" content="ResumeBeen resume builder, create resume, professional resume builder, AI resume builder, customizable resume templates, personalized resume, resume creation tools, career-oriented resume, ATS-friendly resume, resume optimization, cover letter builder"></meta>

        </Helmet>
      </>


      <div className="sticky top-0 z-50 top_nav">
        <Navbar showDashboard={true} />
      </div>
      <div className="flex flex-wrap justify-center max-w-full">
        <div className="flex-1 min-w-[400px] pb-20">
          <div className="bottom_menu_up">
            {/* <div className='flex flex-col flex-wrap content-center justify-center flex-1 py-2 mx-2 text-sm font-semibold bg-white border-black cursor-pointer h-fit border-1 rounded-xl hover:bg-red-300'><FaCogs className='mx-3 w-7 h-7 hover:fill-red-600' />Design</div> */}
            <div className="flex flex-col flex-wrap content-center justify-center flex-1 py-2 mx-2 text-sm font-semibold bg-white border-black cursor-pointer h-fit border-1 rounded-xl">
              <button onClick={handleSaveClick} disabled={saving}>
                <FaDownload className="mx-3 w-7 h-7 hover:fill-red-600" />{" "}
                {saving ? "Saving..." : "Save Now"}
              </button>
            </div>
            <div
              onClick={handleDownloadPDF}
              className="flex flex-col flex-wrap content-center justify-center flex-1 py-2 mx-2 text-sm font-semibold bg-white border-black cursor-pointer h-fit border-1 rounded-xl"
            >
              <FaSave className="mx-3 w-7 h-7 hover:fill-red-600" /> Download
            </div>
            <div
              onClick={handleReset}
              className="flex flex-col flex-wrap content-center justify-center flex-1 py-2 mx-2 text-sm font-semibold bg-white border-black cursor-pointer h-fit border-1 rounded-xl"
            >
              <FaTrash className="mx-3 w-7 h-7 hover:fill-red-600" />
              Clear Resume
            </div>
          </div>
          <div className="template_selector">
            <TemplateSelector
              onSelectTemplate={setSelectedTemplate}
              tempId={selectedTemplate}
              setDataSaved={setDataSaved}
            />
          </div>

          <div className="score_main">
            <div className="score_header">
              <div className="">
                <span className="indicator">{score}%</span>
                <span className="ml-2 text-gray-500">Resume Score</span>
              </div>
            </div>
            <div className="scorer">
              <div className="progress"></div>
              <div
                id="progress_bar"
                className={`progress_bar w-[${score}%]`}
              ></div>
            </div>
          </div>

          <input
            type="text"
            name="resumeTitle"
            value={formData.resumeTitle}
            onChange={handleInputChange}
            className="resume_title mt-4 ml-4 w-[95%] px-3 py-2 border rounded-xl"
            placeholder="Enter Resume Title"
          />
          {/* Toggle and form inputs sections */}
          <div className="p-3 m-3 delay-700 bg-white rounded-lg shadow-xl cursor-pointer accordion-card">
            <div
              onClick={() => setShowPersonalDetails(!showPersonalDetails)}
              className="flex text-lg font-bold cursor-pointer border-s-black flex-inline "
            >
              <img src={userPic} alt="user" className="mx-3 w-7 h-7" /> Personal
              Details
            </div>
            {showPersonalDetails && (
              <DynamicPersonalDetail
                inputs={formData.Details}
                aiToken={aiToken}
                resser={formData.resumeId}
                setAiToken={setAiToken}
                setInputs={(newDetails) =>
                  setFormData({ ...formData, Details: newDetails })
                }
                setDataSaved={setDataSaved}
                resumeId={formData?.resumeId}
              />
            )}
          </div>

          {/* Dynamic Education Inputs */}
          <div className="p-3 m-3 delay-700 bg-white rounded-lg shadow-xl cursor-pointer accordion-card">
            <div
              className="flex"
              onClick={() => setShowEducation(!showEducation)}
            >
              <div className="flex text-lg font-bold cursor-pointer border-s-black flex-inline ">
                <img src={graduation} alt="user" className="mx-3 w-7 h-7" />{" "}
                Education
              </div>{" "}
              <Toggle visible={visible1} onClick={toggleEdu} />
            </div>
            {showEducation && (
              <DynamicEducationInputs
                inputs={formData.education}
                setInputs={(newEducation) =>
                  setFormData({ ...formData, education: newEducation })
                }
                setDataSaved={setDataSaved}
              />
            )}
          </div>

          {/* Dynamic Experience Inputs */}

          <div className="p-3 m-3 delay-700 bg-white rounded-lg shadow-xl cursor-pointer accordion-card">
            <div
              className="flex"
              onClick={() => setShowExperience(!showExperience)}
            >
              <div className="flex text-lg font-bold cursor-pointer border-s-black flex-inline ">
                <img src={portfolio} alt="user" className="mx-3 w-7 h-7" /> Work
                Experience{" "}
              </div>{" "}
              <Toggle visible={visible2} onClick={toggleWorkExp} />
            </div>
            {showExperience && (
              <DynamicExperienceInputs
                inputs={formData.experience}
                aiToken={aiToken}
                setAiToken={setAiToken}
                setInputs={(newExperience) =>
                  setFormData({ ...formData, experience: newExperience })
                }
                setDataSaved={setDataSaved}
                resumeId={formData?.resumeId}
              />
            )}
          </div>
          <div className="p-3 m-3 delay-700 bg-white rounded-lg shadow-xl cursor-pointer accordion-card">
            <div className="flex" onClick={() => setShowSkills(!showSkills)}>
              <div className="flex text-lg font-bold cursor-pointer border-s-black flex-inline ">
                <img src={pencil} alt="user" className="mx-3 w-7 h-7" /> Skills{" "}
              </div>
              <Toggle visible={visible3} onClick={toggleSkills} />
            </div>
            {showSkills && (
              <DynamicSkillsInputs
                inputs={formData.skills}
                setInputs={(newSkills) =>
                  setFormData({ ...formData, skills: newSkills })
                }
                setDataSaved={setDataSaved}
              />
            )}
          </div>

          <div className="p-3 m-3 delay-700 bg-white rounded-lg shadow-xl cursor-pointer accordion-card">
            <div
              className="flex"
              onClick={() => setShowProjects(!showProjects)}
            >
              <div className="flex text-lg font-bold cursor-pointer border-s-black flex-inline ">
                <img src={project} alt="user" className="mx-3 w-7 h-7" />{" "}
                Projects Developed OR Handled
              </div>{" "}
              <Toggle visible={visible4} onClick={toggleProject} />
            </div>
            {showProjects && (
              <DynamicProject
                inputs={formData.projects}
                aiToken={aiToken}
                setAiToken={setAiToken}
                setInputs={(newAddproject) =>
                  setFormData({ ...formData, projects: newAddproject })
                }
                setDataSaved={setDataSaved}
                resumeId={formData?.resumeId}
              />
            )}
          </div>
          <div className="p-3 m-3 delay-700 bg-white rounded-lg shadow-xl cursor-pointer accordion-card">
            <div
              className="flex"
              onClick={() => setShowAchivAwards(!showAchivAwards)}
            >
              <div className="flex text-lg font-bold cursor-pointer border-s-black flex-inline ">
                <img src={trophy} alt="user" className="mx-3 w-7 h-7" /> Awards
                & Achivements{" "}
              </div>
              <Toggle visible={visible5} onClick={toggleachievements} />
            </div>
            {showAchivAwards && (
              <DynamicAchivAward
                inputs={formData.achivawards}
                setInputs={(newAchivAwards) =>
                  setFormData({ ...formData, achivawards: newAchivAwards })
                }
                setDataSaved={setDataSaved}
              />
            )}
          </div>
          <div className="p-3 m-3 delay-700 bg-white rounded-lg shadow-xl cursor-pointer accordion-card ">
            <div
              className="flex"
              onClick={() => setShowaddInfos(!showaddInfos)}
            >
              <div className="flex text-lg font-bold cursor-pointer border-s-black flex-inline">
                <img src={menu} alt="user" className="mx-3 w-7 h-7" />{" "}
                Additional Information
              </div>
              <Toggle visible={visible6} onClick={toggleadditions} />
            </div>
            {showaddInfos && (
              <DynamicAddInfos
                inputs={formData.additionalInfo}
                setInputs={(newAddInfos) =>
                  setFormData({ ...formData, additionalInfo: newAddInfos })
                }
                setDataSaved={setDataSaved}
              />
            )}
          </div>
        </div>
        <div
          className={`flex-1 px-5 pb-20 pt-2 border-l-2 sticky min-w-[400px] right_panel ${isDownloading ? "resume-visible" : ""
            }`}
        >
          <TemplateSelector onSelectTemplate={setSelectedTemplate} tempId={selectedTemplate} setDataSaved={setDataSaved} />
          <div className="flex justify-between mr-3">
            <div className="previewBtn" onClick={togglePreview}>
              <BsArrowsFullscreen className="mr-1 font-extrabold" /> Preview
            </div>
            <div className="px-2 py-1 mb-2 rounded-lg save-status bg-slate-500 w-fit">
              {isSaving ? (
                <div className="flex flex-wrap items-center justify-center gap-1 text-sm">
                  <FaSpinner className="text-xl icon animate-spin" />
                  <span className="text-white">Saving...</span>
                </div>
              ) : saveStatus === "success" ? (
                <div className="flex flex-wrap items-center justify-center gap-1 text-sm">
                  <BsCloudCheckFill className="text-xl icon" />
                  <span className="text-white">Saved</span>
                </div>
              ) : saveStatus === "failed" ? (
                <div className="flex flex-wrap items-center justify-center gap-1 text-sm">
                  <BsExclamationTriangleFill className="text-xl icon" />
                  <span className="text-white">Failed AutoSave</span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="ml-[-10px] w-full border-2 border-gray-600">
            <div ref={containerRef}>
              <PDFExport
                paperSize="A4"
                fileName={`${fetchDatas?.outputData?.name}_Resume${new Date().getFullYear()}`}
                author="https://resumeBeen.com"
              >
                {selectedTemplate === "template6" ? (
                  <Template6 formData={formData} />
                ) : (
                  renderTemplate()
                )}
              </PDFExport>
            </div>

          </div>
        </div>
      </div>

      {showPreview && (
        <div className="preview-overlay">
          <div className="outer-modal">
            <button onClick={togglePreview} className="close-preview-button">
              X
            </button>
            <div className="preview-modal">
              <div className="temp_in">
                <div
                  className="py-3 transformer"
                  style={{ transform: `scale(${scaleRatio})` }}
                >
                  <div className="preview-content">
                    <div
                      id="printArea"
                      className="border-[3px] border-gray-300 rounded-xl shadow-xl mb-4"
                    >
                      <PDFExport
                        paperSize="A4"
                        className="h-0.5"
                        fileName={
                          fetchDatas?.outputData?.name +
                          `_Resume${new Date().getFullYear()}`
                        }
                        author="https://resumeBeen.com"
                      >
                        {renderTemplate()}
                      </PDFExport>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isDownloading && (
        <div className="download-overlay">
          <img
            src={downloadingGif}
            alt="Downloading"
            className="download-overlay-gif"
          />
          <span className="download-overlay-text">Downloading...</span>
        </div>
      )}

      <div className="floating_save">
        <div className="px-2 py-1 mb-2 rounded-lg save-status bg-slate-500 w-fit">
          {isSaving ? (
            <div className="flex flex-wrap items-center justify-center gap-1 text-sm">
              <FaSpinner className="text-xl icon animate-spin" />
              <span className="text-white">Saving...</span>
            </div>
          ) : saveStatus === "success" ? (
            <div className="flex flex-wrap items-center justify-center gap-1 text-sm">
              <BsCloudCheckFill className="text-xl icon" />
              <span className="text-white">Saved</span>
            </div>
          ) : saveStatus === "failed" ? (
            <div className="flex flex-wrap items-center justify-center gap-1 text-sm">
              <BsExclamationTriangleFill className="text-xl icon" />
              <span className="text-white">Failed AutoSave</span>
            </div>
          ) : null}
        </div>
      </div>

      <div className="bottom_nav">
        {/* Bottom menu */}
        {/* <button className="menu-button" ><FaCogs className="text-base"/>Design</button> */}
        <button
          className="menu-button"
          onClick={handleSaveClick}
          disabled={saving}
        >
          <FaSave className="text-base" />
          {saving ? "Saving..." : "Save"}
        </button>
        <button className="menu-button" onClick={togglePreview}>
          <FaEye className="text-base" />
          Preview
        </button>
        <button className="menu-button" onClick={handleDownloadPDF}>
          <FaDownload className="text-base" />
          Download
        </button>
        <div className="menu-button" onClick={handleReset}>
          <FaTrash className="text-base" />
          Reset
        </div>
      </div>
    </div>
  );
};

export default ResumeBuilder;
