import React, { useEffect, useRef } from "react";
import "./Template9.css";

const Template9 = ({ formData }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const updateHeight = () => {
            const container = containerRef.current;
            if (container) {
                const containerWidth = container.offsetWidth;
                container.style.height = "auto"; // Allow height to adjust based on content
            }
        };
        updateHeight();
        window.addEventListener("resize", updateHeight);
        return () => {
            window.removeEventListener("resize", updateHeight);
        };
    }, []);

    // Safe access for formData and its properties
    const details = formData?.Details || []; // Default to an empty array if formData.Details is undefined
    const skills = formData?.skills || []; // Default to an empty array if formData.skills is undefined
    const education = formData?.education || []; // Default to an empty array if formData.education is undefined

    // Check for flags
    const skillFlag = formData?.skillFlag || false;
    const eduFlag = formData?.eduFlag || false;
    const workFlag = formData?.workFlag || false;

    return (
        <div className="template-container9 p-0 w-full" ref={containerRef}>
                    {details[0] && (
                        <div className="header-profile">
                            <div className="profname basis-[55%] grow-[3]">
                                <p className="profile9">{details[0].fullName}</p>
                            </div>
                             <div className="profimage basis-[45%] grow-[2]">
                                {formData.Details &&
                                    formData.Details.map((detail, index) => (
                                        detail.profileImage ? (
                                            <img
                                                key={index}
                                                src={detail.profileImage}
                                                alt="Profile"
                                                className="profile-image9"
                                            />
                                        ) : null
                                    ))
                                }
                            </div>
                        </div>
                    )}
                    <div>
                            <div className="headerdesc">
                                <div className="lefthead basis-[55%] grow-[3]">
                                <h2 className="section-title9">About ME</h2>
                                </div>
                                <div className="righthead basis-[45%] grow-[2]">
                                    <h2 className="section-title9">Contact</h2>
                                </div>
                            </div>
                            <div className="downtext">
                                <div className="lefttext basis-[55%] grow-[3]">
                                        {details[0]?.about && (
                                            <div className="smallText7">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: details[0].about,
                                                    }}
                                                />
                                            </div>
                                        )}
                                </div>
                                <div className="righttext basis-[45%] grow-[2]">
                                {details.map((detail, index) => (
                                    <React.Fragment key={index}>
                                        <div className="conta">
                                            <p>
                                                <i className="fa fa-phone contactIcon" aria-hidden="true"></i>
                                                {detail.phone}
                                            </p>
                                            <p>
                                                <i className="fa fa-envelope contactIcon" aria-hidden="true"></i>
                                                <a href={`mailto:${detail.email}`}>{detail.email}</a>
                                            </p>
                                            <p>
                                                <i className="fa fa-map-marker contactIcon" aria-hidden="true"></i>
                                                {detail.address}
                                            </p>
                                            {detail.linkedin && (
                                                <p>
                                                    <i className="fa fa-linkedin-square contactIcon" aria-hidden="true"></i>
                                                    <a href={detail.linkedin}>{detail.linkedin}</a>
                                                </p>
                                            )}
                                        </div>
                                    </React.Fragment>
                                ))}
                                </div>
                                

                            </div>
                    </div>
                    <div>
                            <div className="headerdesc">
                                <div className="lefthead basis-[55%] grow-[3]">
                                <h2 className="section-title9">EXPERIENCE</h2>
                                </div>
                                <div className="righthead basis-[45%] grow-[2]">
                                            <h2 className="section-title9">EDUCATION</h2>
                                </div> 
                            </div>
                            <div className="downtext">
                                <div className="basis-[55%] grow-[3]">
                                    <div className="skill9" >
                                        {formData?.experience &&
                                        formData?.experience.map((exp, index) => (
                                        <div key={index} className="section-content">
                                            <p className="bold-text">
                                                {exp.jobRole} {(exp?.jobRole && exp?.companyLocation) ? "at" : ""} {exp.companyName}
                                            </p>
                                            <p>{exp.companyLocation}</p>
                                            <p>
                                                {exp?.fromDate || ""} {(exp?.fromDate && exp?.toDate) ? "-" : ""} {exp?.toDate || ""}
                                            </p>
                                            <p dangerouslySetInnerHTML={{ __html: exp.jobDescription }} />
                                        </div>
                                        ))}
                                    </div>
                                    <div>
                                        <div className="lefthead basis-[55%] grow-[3]">
                                            <h2 className="section-title9 awar">AWARDS</h2>
                                        </div>
                                        <div className="exper">
                                                {formData?.achivawards && formData?.achivawards.map((ach, index) => (
                                                    <div key={index} className="section-content">
                                                    <p>{ach.achivawards}</p>
                                                    </div>
                                                ))}
                                        </div>
                                   </div>
                                </div>
                                <div className="righttext basis-[45%] grow-[2]">
                                   {education.map((edu, index) => (
                                                    <div className="edu9" key={index}>
                                                        <p className="bolded white">{edu.degree}</p>
                                                        <p>{edu.college}</p>
                                                        <p>
                                                            {edu.fromDate} - {edu.toDate}
                                                        </p>
                                                    </div>
                                                ))}
                                    <div>
                                    {skillFlag && (
                                <div className="righthead ">
                                    <h2 className="section-title9">SKILLS</h2>
                                </div>
                                )}
                                        <div className="skill9">
                                        {skillFlag && (
                                    <div className="skillsalign">
                                                {formData?.skills.map((skill, index) => (
                                                    <div className="section-content" key={index}>
                                                        <div>
                                                            <span className="skillstext">{`${skill?.title ? `${skill?.title} - `: ""}`}</span>{skill.skillList}
                                                        </div>
                                                        <div className="yearsOfExperience">
                                                            <span>{skill.years}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                    </div>
                                    )}
                                        </div>      
                                    </div>
                                    <div>
                                        <div className="righthead">
                                            <h2 className="section-title9">PROJECT DEVELOPED OR HANDLED</h2>
                                        </div> 
                                                {formData.proFlag &&
                                                    <div className="skill9">
                                                        {formData?.projects && formData?.projects.map((prodit, index) => (
                                                            <div key={index} className="smallText7 proddesc">
                                                                <p className="bold-text">{prodit.title} {(prodit.title && prodit.stack) ? "-":""} {prodit.stack}</p>
                                                                <p dangerouslySetInnerHTML={{ __html: prodit.desc }} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                }   
                                    </div>
                                    <div>
                                    {formData.addFlag && (
                                    <div className="">
                                        <div className="righthead">
                                            <h2 className="section-title9">Additional Information</h2>
                                        </div>
                                        {formData?.additionalInfo &&
                                        formData?.additionalInfo.map((addinfo, index) => (
                                            <div key={index} className="section-content">
                                            <p>{addinfo.addInfo}</p>
                                            </div>
                                        ))}
                                    </div>
                                    )}
                                    </div>
                                </div>
                                

                            </div>
                    </div>
        </div>
    );
};

export default Template9;




