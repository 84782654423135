import React, { useRef } from "react";
import resume1 from "../image/resume1.jpg";
import resume2 from "../image/resume2.jpg";
import resume3 from "../image/resume3.jpg";
import resume4 from "../image/resume4.jpg"
import resume5 from "../image/resume4.jpg"
import resume6 from "../image/resume6.JPG"
import resume7 from "../image/resume7.JPG"
import resume8 from "../image/resume8.PNG"
import resume9 from "../image/resume9.JPG"
import resume10 from "../image/resume10.JPG"
import resume11 from "../image/resume11.JPG"
import resume12 from "../image/resume12.JPG"
import "./TemplateSelector.css";
// import Template1 from './Template1';
// import Template2 from './Template2';
// import Template3 from './Template3';

const templates = [
  { id: "template9", name: "Creative", image: `${resume9}` },
  { id: "template10", name: "Creative", image: `${resume10}`},
  { id: "template11", name: "Creative", image: `${resume11}`},
  { id: "template12", name: "Creative", image: `${resume12}`},
  { id: "template8", name: "Creative", image: `${resume8}` },
  { id: "template7", name: "Creative", image: `${resume7}` },
  { id: "template6", name: "Creative", image: `${resume6}` },
  { id: "template4", name: "Creative", image: `${resume4}` },
  { id: "template1", name: "Classic", image: `${resume1}` },
  { id: "template2", name: "Modern", image: `${resume2}` },
  { id: "template3", name: "Creative", image: `${resume3}` },
  { id: "template9", name: "Creative", image: `${resume9}` },
  { id: "template10", name: "Creative", image: `${resume10}`},
  { id: "template11", name: "Creative", image: `${resume11}`},
  { id: "template12", name: "Creative", image: `${resume12}`},
  { id: "template8", name: "Creative", image: `${resume8}` },
  { id: "template7", name: "Creative", image: `${resume7}` },
  { id: "template6", name: "Creative", image: `${resume6}` },
  { id: "template4", name: "Creative", image: `${resume4}` },
  { id: "template1", name: "Classic", image: `${resume1}` },
  { id: "template2", name: "Modern", image: `${resume2}` },
  { id: "template3", name: "Creative", image: `${resume3}` },
  { id: "template9", name: "Creative", image: `${resume9}` },
  { id: "template10", name: "Creative", image: `${resume10}`},
  { id: "template11", name: "Creative", image: `${resume11}`},
  { id: "template12", name: "Creative", image: `${resume12}`},
  { id: "template8", name: "Creative", image: `${resume8}` },
  { id: "template7", name: "Creative", image: `${resume7}` },
  { id: "template6", name: "Creative", image: `${resume6}` },
  { id: "template9", name: "Creative", image: `${resume9}` },
  { id: "template10", name: "Creative", image: `${resume10}`},
  { id: "template11", name: "Creative", image: `${resume11}`},
  { id: "template12", name: "Creative", image: `${resume12}`},
];
// setDataSaved={setDataSaved}

const TemplateSelector = ({ onSelectTemplate, tempId,setDataSaved }) => {
  let imageContainerRef = useRef(null);
  // console.log({onSelectTemplate})
  const prev = () => (imageContainerRef.current.scrollLeft -= 250);
  const next = () => (imageContainerRef.current.scrollLeft += 250);
  return (
    <>
      <div className="flex flex-row mb-3">
        <div>
          <section className="flex flex-col items-center px-8 pt-10 pb-2 text-4xl text-center text-white rounded-3xl bg-zinc-800 w-24 max-w-[392px]">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a1d1ad9ae56007534f1b5b66406902c97d6c0b099696a2c1eac908cbcf42ec6?apiKey=e64c88474a2c4290b8b66f50b4a39c22&"
              alt="Description of the"
              className="max-w-full aspect-[0.96] w-[187px]"
            />
            <p className="mt-3 mb-2 text-xs font-semibold">Select Template</p>
          </section>
        </div>
        <div className="page_containers">
          <div className="contents">
            {/* <div className='prevs' onClick={prev}></div> */}
            <div className="slide-panels" ref={imageContainerRef}>
              {templates.map((template,idx) => (
                <img
                  src={template.image}
                  alt="Resume Template"
                  name={template.name}
                  key={idx}
                  className="m-2 border rounded hover:bg-gray-200 w-32 h-[140px] object-cover"
                  onClick={() => {
                  onSelectTemplate(template.id || tempId)
                  setDataSaved(false)
                  }
                  }
                />
              ))}
            </div>
            <div className="nexts" onClick={next}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateSelector;
