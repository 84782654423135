import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import RoopTable from "../../common/customTableR/table";
import useGlobalStore from "../../../store/global";
import useGlobalLoaderStore from "../../../store/loader";
import { extractErrorMessage } from "../../../utils/utils";
import { API_URL } from "../../../apiConfig";
import { PDFExport } from "@progress/kendo-react-pdf";
import Template1 from "../../template/Template1";
import Template2 from "../../template/Template2";
import Template3 from "../../template/Template3";
import downloadingGif from "../../../image/downloading.gif";

const Resume = () => {
  const { setToastData } = useGlobalStore();
  const { isLoading, setLoading } = useGlobalLoaderStore();
  const [resumes, setResumes] = useState([]);
  const pdfRef = useRef(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [fileName, setFileName] = useState("");
  const [page, setPage] = useState(1);
  const [openAccordion, setOpenAccordion] = useState(null); // State for accordion

  // Export Resume
  const exportPDF = async () => {
    if (!isDownloading && pdfRef.current && formData) {
      setIsDownloading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      pdfRef.current.save();
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/admin/user-resumes?page=${page}`,
          { withCredentials: true }
        );

        setResumes(
          response?.data?.data.map((el, index) => ({
            count: index + 1,
            userId: el?.userId,
            userName: el?.userName ?? "",
            email:el?.email ?? "",
            totalResumes: el?.resumes?.length,
            resumes: el?.resumes.map((res) => ({
              _id: res?._id,
              resumeTitle: res?.resumeTitle || "Untitled",
            })),
          }))
        );
      } catch (error) {
        console.error("Error fetching resumes:", error);
        const errorMessage = extractErrorMessage(error);
        setToastData({
          type: "error",
          message: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [setLoading, page]);

  const handleDownloadResume = async (resumeId) => {
    if (!resumeId) {
      setToastData({
        type: "error",
        message: "Resume ID is required.",
      });
      return;
    }

    try {
      const response = await axios.get(`${API_URL}/api/v1/admin/get-resume`, {
        params: { resumeId },
        withCredentials: true,
      });

      const item = response?.data?.data;
      if (!item) {
        setToastData({
          type: "error",
          message: "Resume not found.",
        });
        return;
      }

      // Set the form data to the state
      setFormData({
        resumeId: item?.resumeId || "",
        resumeTitle: item?.resumeTitle || "Untitled",
        eduFlag: item?.eduFlag ?? true,
        workFlag: item?.workFlag ?? true,
        achFlag: item?.achFlag ?? true,
        addFlag: item?.addFlag ?? true,
        skillFlag: item?.skillsFlag ?? true,
        proFlag: item?.proFlag ?? true,
        Details: item?.personalDetails
          ? [
              {
                fullName: item?.personalDetails?.fullName || "",
                email: item?.personalDetails?.email || "",
                phone: item?.personalDetails?.phone || "",
                address: item?.personalDetails?.address || "",
                website: item?.personalDetails?.website || "",
                linkedin: item?.personalDetails?.linkedin || "",
                github: item?.personalDetails?.github || "",
                about: item?.personalDetails?.summary || "",
              },
            ]
          : [],
        education: item?.education || [],
        experience: (item?.experience ?? []).map((exp) => ({
          jobRole: exp?.jobRole || "",
          companyName: exp?.companyName || "",
          companyLocation: exp?.companyLocation || "",
          fromDate: exp?.fromDate || "",
          toDate: exp?.toDate || "",
          jobDescription: exp?.responsibilities,
        })),
        skills: (item?.skills ?? []).map((skill) => ({
          title: skill?.title || "",
          skillList: skill?.skillList.length > 0 ? skill?.skillList?.join(",") || "" : "",
        })),
        achivawards: (item?.achivawards ?? []).map((achi) => ({
          achivawards: achi || "",
        })),
        additionalInfo: (item?.additionalInfo ?? []).map((info) => ({
          addInfo: info?.addInfo || "",
        })),
        projects: (item?.projects ?? []).map((proj) => ({
          title: proj?.title || "",
          stack: proj?.stack?.length > 0 ? proj?.stack?.join(",") || "" : '',
          desc: proj?.desc ? proj?.desc.toString() : "",
        })),
        templateKey: item?.templateKey,
      });

      const userName = resumes?.filter((el) => el?.resumeId === item?.resumeId)[0]?.userName;

      setFileName(item?.resumeTitle
        ? item?.resumeTitle
        : userName + `_Resume${new Date().getFullYear()}`);
      await exportPDF();
    } catch (error) {
      console.error("Error downloading resume:", error);
      setToastData({
        type: "error",
        message: "Failed to download resume.",
      });
    }
  };

  const headings = [
    { dataKey: "count", title: "Count" },
    { dataKey: "userName", title: "User Name" },
    { dataKey: "email", title: "Email" },
    {
      dataKey: "resumes",
      title: "Resumes",
      render: (row) => (
        <div>
          <button
            className="px-4 py-2 transition-all bg-gray-200 rounded-lg cursor-pointer hover:bg-primary hover:text-white"
            onClick={() => setOpenAccordion(openAccordion === row.userId ? null : row.userId)} // Toggle accordion for the user
          >
            {row.userName}
          </button>
          {openAccordion === row.userId && (
            <div className="flex flex-wrap gap-2 mt-2"> {/* Capsule container */}
              {row.resumes.map((resume) => (
                <div key={resume._id} className="flex-shrink-0 px-4 py-2 transition rounded-full hover:bg-[#1F6463] hover:text-white">
                  <button
                    onClick={() => handleDownloadResume(resume._id)}
                    className="block text-left"
                  >
                    {resume.resumeTitle}
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="w-full px-2 mx-auto">
      {isDownloading && (
        <div className="download-overlay">
          <img
            src={downloadingGif}
            alt="Downloading"
            className="download-overlay-gif"
          />
          <span className="download-overlay-text">Downloading...</span>
        </div>
      )}

      <RoopTable
        loading={isLoading}
        data={resumes}
        itemsPerPage={10}
        headings={headings}
        hovered
        setPage={setPage}
      />
      {/* PDF Export Component */}
      <PDFExport ref={pdfRef} paperSize="A4" author="https://resumeBeen.com" fileName={fileName}>
        <div className={`dash_in ${isDownloading ? "resume-visible" : "hidden"}`}>
          <div className="dash_transformer">
            {formData?.templateKey === "template1" && (
              <Template1 formData={formData} />
            )}
            {formData?.templateKey === "template2" && (
              <Template2 formData={formData} />
            )}
            {formData?.templateKey === "template3" && (
              <Template3 formData={formData} />
            )}
          </div>
        </div>
      </PDFExport>
    </div>
  );
};

export default Resume;
